<template>
  <v-container fluid class="fill-height align-content-start">
    <v-overlay :value="showOverlay" absolute>
      <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-row
      align="center"
      justify="center"
      v-for="loan in loans"
      :key="loan.loanNumber"
    >
      <v-col cols="12" sm="8" md="6" lg="5" xl="3">
        <increase-eligible-widget
          :loan="loan"
          v-if="loan.increaseEligibleAmount > 0"
        ></increase-eligible-widget>
        <loan-detail-card :loan="loan"></loan-detail-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import IncreaseEligibleWidget from '../components/IncreaseEligibleWidget';
import LoanDetailCard from '../components/LoanDetailCard';
import { loanService } from '../services/loan.service';
export default {
  name: 'LoanDetails',
  components: {
    LoanDetailCard,
    IncreaseEligibleWidget,
  },
  data() {
    return {
      loans: [],
      loaded: false,
      overlay: false,
    };
  },
  computed: {
    ...mapGetters({
      token: 'auth/token',
      isPresto: 'company/isPresto',
      locationsLoaded: 'location/loaded',
    }),
    showOverlay() {
      return this.overlay || !this.locationsLoaded;
    },
  },
  created() {
    this.$store.dispatch('location/getLocations');
    this.getLoans();
  },
  methods: {
    getLoans() {
      this.overlay = true;
      loanService
        .getLoans()
        .then(
          (result) => {
            if (result.succeeded) {
              this.loans = result.data;
              this.loaded = true;
            }
          },
          () => {
            this.$store.commit(
              'snackbar/showSnack',
              'Unable to load loan details',
            );
          },
        )
        .finally(() => {
          this.overlay = false;
        });
    },
  },
};
</script>
