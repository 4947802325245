<template>
  <centered-content-area>
    <location-card :location="location"></location-card>
  </centered-content-area>
</template>

<script>
import { mapGetters } from 'vuex';
import CenteredContentArea from '../components/CenteredContentArea';
import LocationCard from '../components/LocationCard.vue';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Location',
  components: {
    CenteredContentArea,
    LocationCard,
  },
  data() {
    return {
      location: null,
    };
  },
  created() {
    if (!this.location) {
      this.$store.dispatch('location/getLocations').then(() => {
        this.setLocation();
      });
    }
  },
  methods: {
    setLocation() {
      this.location = this.getLocationById(this.$route.params.locationId);
    },
    getEscapedAddress(location) {
      return encodeURI(
        `${location.address1}, ${location.city}, ${location.stateCode}`,
      );
    },
    getOrigin() {
      return this.userPosition
        ? `&origin=${this.userPosition.latitude},${this.userPosition.longitude}`
        : '';
    },
  },
  computed: {
    ...mapGetters({
      locations: 'location/locations',
      locationsLoaded: 'location/loaded',
      getLocationById: 'location/getLocationById',
      userPosition: 'location/userPosition',
      isPresto: 'company/isPresto',
    }),
    isXs() {
      return this.$vuetify.breakpoint.xs;
    },
    directionsLink() {
      return `https://www.google.com/maps/dir/?api=1&destination=${this.getEscapedAddress(
        this.location,
      )}${this.getOrigin()}`;
    },
  },
};
</script>
