import { stateService } from '../../services/state.service';

const state = {
  all: [],
  loaded: false,
};

const getters = {
  all: (s) => s.all,
  loaded: (s) => s.loaded,
};

const actions = {
  clear: ({ commit }) => {
    commit('clear');
  },
  get: ({ commit, state }) => {
    return new Promise((resolve, reject) => {
      if (state.loaded) {
        resolve();
      } else {
        stateService.get().then(
          (result) => {
            if (result.succeeded) {
              commit('all', result.data);
            }
            resolve(result);
          },
          (errors) => {
            reject(errors);
          },
        );
      }
    });
  },
};

const mutations = {
  all(state, states) {
    state.all = states;
    state.loaded = true;
  },
  clear: () => {
    // No need to clear states
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
