<template>
  <v-app id="app" v-bind:class="{ presto: isPresto, CT: !isPresto }">
    <v-slide-y-transition mode="out-in">
      <router-view class="fill-height" />
    </v-slide-y-transition>
    <check-for-updates />
    <auth-timer />
    <snackbar />
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import Snackbar from './components/Snackbar';
import AuthTimer from './components/AuthTimer';
import CheckForUpdates from './components/CheckForUpdates';

export default {
  name: 'App',
  components: {
    Snackbar,
    AuthTimer,
    CheckForUpdates,
  },
  created() {
    let companyId = window.location.href.indexOf('presto') > -1 ? 5 : 1;
    if (companyId == 5) {
      this.$store.commit('company/setIsPresto');
      document.title = 'My Presto';
    } else {
      document.title = 'My Cash Time';
    }
    this.$store.dispatch('company/getCompanyById', companyId);

    let favicon = document.querySelector('link[rel="icon"]');
    if (favicon && companyId === 5) {
      favicon.href = '/presto-favicon.png';
    }
  },
  computed: {
    ...mapGetters({
      isPresto: 'company/isPresto',
    }),
  },
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(245, 245, 245);
}
</style>
