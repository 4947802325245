const state = {
  snack: '',
  timeout: 5000,
  isError: false,
};

const actions = {
  clear: ({ commit }) => {
    commit('clear');
  },
  showSnack: ({ commit }, args) => {
    if (!args.timeout) {
      args.timeout = 5000;
    }
    if (args.isError) {
      commit('setError', args.isError);
    }
    commit('setTimeout', args.timeout);
    commit('showSnack', args.message);
  },
};

const mutations = {
  setError(state, isError) {
    state.isError = isError;
  },
  setTimeout(state, timeout) {
    state.timeout = timeout;
  },
  showSnack(state, snack) {
    state.snack = snack;
  },
  clear: (snackState) => {
    snackState.snack = '';
    snackState.isError = false;
  },
};

export default {
  state,
  actions,
  mutations,
};
