<template>
  <div class="account-card-layout">
    <div class="account-card-content-container">
      <div class="progress-container">
        <slot name="progress" />
      </div>
      <div class="account-card-content">
        <ct-logo v-if="!isPresto" />
        <presto-logo v-else />
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CtLogo from '../../components/CtLogo';
import PrestoLogo from '../../components/PrestoLogo';
export default {
  name: 'AccountCard',
  components: {
    CtLogo,
    PrestoLogo,
  },
  data: function () {
    return {
      isBusy: false,
    };
  },
  computed: {
    ...mapGetters({
      isPresto: 'company/isPresto',
    }),
  },
};
</script>

<style lang="scss" scoped>
@media (max-height: 700px) {
  .account-card-content {
    height: 100%;
  }
}

@media (max-width: 400px) {
  .account-card-layout,
  .account-card-content-container {
    width: 100% !important;
    padding: 0 !important;
    height: 100%;
    max-width: 100% !important;
    border-radius: 0 !important;
  }

  .account-card-layout {
    max-width: 100%;
    width: 100%;
    height: 100%;
    max-height: 100%;
  }
}

.account-card-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  .account-card-content-container {
    width: 364px;
    max-width: 364px;
    background-color: #fff;
    box-shadow:
      0 8px 10px -5px rgba(0, 0, 0, 0.2),
      0 16px 24px 2px rgba(0, 0, 0, 0.14),
      0 6px 30px 5px rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    .progress-container {
      min-height: 7px;
      height: 7px;
    }
    .account-card-content {
      padding: 32px;
      text-align: center;
    }
  }
}
</style>
