const state = {
  version: null,
  loaded: false,
};

const getters = {
  clientVersion: (s) => s.version,
};

const actions = {
  clear: ({ commit }) => {
    commit('clear');
  },
};

const mutations = {
  setVersion(state, version) {
    state.version = version;
    state.loaded = true;
  },
  clear: () => {
    // No need to clear anything
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
