import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

let ctTheme = {
  primary: '#0052a6',
  secondary: '#eb1c2f',
  accent: '#82B1FF',
  error: '#FF5252',
  info: '#2196F3',
  success: '#4CAF50',
  warning: '#FFC107',
};

let prestoTheme = {
  primary: '#231f20',
  secondary: '#CE181E',
  accent: '#82B1FF',
  error: '#FF5252',
  info: '#2196F3',
  success: '#4CAF50',
  warning: '#FFC107',
};

let theme = window.location.href.indexOf('presto') > -1 ? prestoTheme : ctTheme;

export default new Vuetify({
  theme: {
    themes: {
      light: theme,
    },
  },
});
