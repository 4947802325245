<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-form v-model="valid" ref="makePaymentForm" @keyup.native.enter="payNow">
    <v-card v-if="loan">
      <v-card-title>Title Loan {{ loan.loanNumber }}</v-card-title>
      <error-display :errors="errors"></error-display>
      <v-card-text>
        <loan-detail-basic-info
          :loan="loan"
          :location="location"
        ></loan-detail-basic-info>
        <hr
          role="separator"
          aria-orientation="horizontal"
          class="v-divider theme--light mb-5"
        />
        <name-value-pair-row
          name="Payment Date"
          :value="paymentInfo.paymentDate | formatDate"
          class="font-bold"
        >
        </name-value-pair-row>
        <v-container class="pt-0 pb-0">
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" md="10" lg="6" xl="4" class="pa-0 mb-2">
              <v-currency-field
                class="v-input-right-align"
                v-model="paymentInfo.paymentAmount"
                label="Payment Amount"
                prefix="$"
                :rules="[
                  paymentAmountRules.required,
                  paymentAmountRules.greaterThanPayoff,
                  paymentAmountRules.withinDailyLimit,
                ]"
                v-focus
                ref="amt"
              ></v-currency-field>
            </v-col>
          </v-row>
        </v-container>
        <div class="pl-5" style="position: relative" v-if="!loan.isArd">
          <name-value-pair-row
            name="Interest and Fees"
            :animate-value="true"
            :value="paymentInfo.interestAndFees | currency"
          >
          </name-value-pair-row>
          <name-value-pair-row
            name="Principal"
            :animate-value="true"
            :value="paymentInfo.principal | currency"
          >
          </name-value-pair-row>
        </div>
        <v-container class="pt-0 pb-0" v-if="paymentMethods">
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" md="10" lg="6" xl="4" class="pa-0 mb-2">
              <v-select
                :items="paymentMethods"
                label="Payment Method"
                item-text="displayName"
                item-value="key"
                :item-disabled="disableAchItem"
                return-object
                :rules="[paymentMethodRules]"
                v-model="paymentInfo.paymentMethod"
                :loading="loadingPaymentMethods"
                class="mb-2"
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
        <div class="ta-r pb-2">
          <!-- <add-payment-method-dialog
            v-on:paymentMethodAdded="appendPaymentMethod"
            :loan="loan"
          ></add-payment-method-dialog> -->
          <manage-payment-methods-dialog
            v-on:paymentMethodAdded="appendPaymentMethod"
            v-on:onPaymentMethodRemoved="paymentMethodRemoved"
            :loan="loan"
            :paymentMethods="paymentMethods"
          ></manage-payment-methods-dialog>
        </div>
        <div
          v-if="loan.achPaymentsDisabled && hasAchPaymentMethods"
          class="pt-4"
        >
          The ACH payment method is currently disabled and cannot be selected.
          Please select a debit card payment method or contact your loan center
          at
          <a
            :href="`tel:+${location.phone}`"
            class="td-n"
            style="display: inline"
            >{{ location.phone | formatPhone }}</a
          >
          for further assistance.
        </div>
      </v-card-text>
      <separator class="mb-2 mt-1" />
      <div class="ta-j pa-3">
        <v-slide-y-transition>
          <v-alert
            type="warning"
            icon="warning"
            class="elevation-1"
            border="top"
            v-if="isDuplicateAchPayment"
          >
            An ACH payment was posted to your account on
            {{ loan.lastPaymentDate | formatDate }} for
            {{ loan.lastPaymentAmount | currency }}. Only one ACH payment can be
            made per day on an account for the same amount.
          </v-alert>
        </v-slide-y-transition>
        <v-slide-y-transition mode="out-in">
          <v-alert
            type="warning"
            icon="warning"
            class="elevation-1"
            border="top"
            v-if="loan.lastPaymentMadeWithinLastThreeDays"
          >
            <span v-if="loan.lastPaymentType === 'ACH'">An</span
            ><span v-else>A</span> {{ loan.lastPaymentType }} payment was
            recently posted to your account on
            {{ loan.lastPaymentDate | formatDate }} for
            {{ loan.lastPaymentAmount | currency }}. Any payments you make on
            the Customer Portal will be in addition to this recent payment.
          </v-alert>
        </v-slide-y-transition>
        <v-slide-y-transition mode="out-in">
          <v-alert
            type="warning"
            icon="warning"
            class="elevation-1"
            border="top"
            v-if="loan.autoPaySet === 1 && loan.nextAutoPayWithinThreeDays"
          >
            Your account is set up on autopay and you have an upcoming
            {{ loan.autoPaymentType }} payment scheduled on
            {{ loan.nextAutoPaymentDate | formatDate }} for
            {{ loan.nextAutoPaymentAmount | currency }}. Any payments you make
            on the Customer Portal will be in addition to your scheduled
            automatic payment.
          </v-alert>
        </v-slide-y-transition>
        <div v-for="msg in messages" :key="msg" class="pb-3">{{ msg }}</div>
      </div>
      <separator class="pb-2" />
      <v-card-actions class="pb-3">
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          class="mr-1"
          @click="makeAPayment(paymentInfo)"
          :disabled="!valid || hasPaymentsPending || isDuplicateAchPayment"
          >Make a Payment</v-btn
        >
        <v-btn @click="cancel">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex';
import Dollar from '../../lib/dollar';
import ManagePaymentMethodsDialog from './../../dialogs/ManagePaymentMethodsDialog';
import NameValuePairRow from '../NameValuePairRow';
import { loanService } from '../../services/loan.service';
import { paymentMethodService } from '../../services/paymentMethod.service';
import LoanDetailBasicInfo from '../LoanDetailBasicInfo';
import ErrorDisplay from '../ErrorDisplay';
import currency from '../../filters/currency';

export default {
  name: 'MakePaymentComponent',
  props: [
    'loan',
    'formattedAfterHoursCutoff',
    'paymentInfo',
    'errors',
    'isAfterHours',
    'location',
  ],
  components: {
    ManagePaymentMethodsDialog,
    NameValuePairRow,
    LoanDetailBasicInfo,
    ErrorDisplay,
  },
  computed: {
    ...mapGetters({
      getLocationById: 'location/getLocationById',
    }),
    hasPaymentsPending() {
      return this.loan.pendingPaymentsCount !== null;
    },
    isDuplicateAchPayment() {
      return (
        this.loan.paymentMadeToday &&
        this.loan.lastPaymentType === 'ACH' &&
        this.paymentInfo.paymentMethod !== null &&
        this.paymentInfo.paymentMethod.type === 2 &&
        this.paymentInfo.paymentAmount === this.loan.lastPaymentAmount
      );
    },
    hasAchPaymentMethods() {
      if (this.paymentMethods == null || this.paymentMethods.length == 0) {
        return false;
      }

      return (
        this.paymentMethods.filter((pm) => pm.displayName.startsWith('ACH'))
          .length > 0
      );
    },
    messages() {
      let msgs = [];
      if (!this.loan) return msgs;
      var baseMsg;
      if (!this.isAfterHours) {
        baseMsg =
          'Information or transactions may take up to 1 business day to update.';
      } else {
        baseMsg = `If paying after ${this.formattedAfterHoursCutoff}, your payment will be posted to your account the next day.`;
      }
      msgs.push(baseMsg);

      if (this.loan && this.location) {
        let totalDailyAchDebitPayments = new Dollar(
          this.loan.totalDailyAchDebitPayments ?? 0,
        );
        let achDebitDailyLimit = new Dollar(this.location.achDebitDailyLimit);
        let diff = achDebitDailyLimit.subtract(totalDailyAchDebitPayments);
        let payoff = new Dollar(this.loan.payoffAmount);
        if (diff.isGreaterThan(payoff)) {
          diff = payoff;
        }
        msgs.push(`Your accumulated payments for this day is ${currency(
          this.loan.totalDailyAchDebitPayments ?? 0,
        )}. You are able to make a payment of 
        ${currency(
          diff.toFloat(),
        )} before exceeding the daily limit.  If you would like to make a larger payment, please contact the location of your loan.`);
      }

      // This should trigger the 'required' rule
      if (!this.paymentInfo.paymentAmount) {
        return msgs;
      }

      if (this.loan.isArd) {
        return msgs;
      }

      let pmt = new Dollar(this.paymentInfo.paymentAmount);
      let interestAndFees = new Dollar(this.loan.interestAndFeesDue);

      if (this.paymentInfo && this.paymentInfo.paymentAmount) {
        if (pmt.isLessThan(interestAndFees)) {
          msgs.push(
            'Paying less than interest and fees due will not advance your due date.',
          );
        }

        if (pmt.isEqualTo(interestAndFees)) {
          msgs.push(
            'Paying only interest and fees due will not reduce your principal and may impact your loan maturity date.',
          );
        }
      }
      return msgs;
    },
  },
  data() {
    return {
      valid: false,
      timeout: null,
      paymentMethodRules: (v) => !!v || 'Payment Method is required',
      paymentAmountRules: {
        required: (value) => {
          if (typeof value === 'string') {
            value = value.split(',').join('');
          }
          return (!!value && value > 0) || 'Payment Amount is required';
        },
        greaterThanPayoff: (value) => {
          if (!!value && value.length > 0 && value.indexOf(',') > -1) {
            value = value.split(',').join('');
          }

          let pmt = new Dollar(Number(value));
          let payoff = new Dollar(this.loan.payoffAmount);
          return (
            pmt.isLessThanOrEqualTo(payoff) ||
            'Payment Amount cannot be larger than Payoff Amount.'
          );
        },
        withinDailyLimit: (value) => {
          if (this.location == null) return;
          if (value === null || value === undefined) return;
          if (!!value && value.length > 0 && value.indexOf(',') > -1) {
            value = value.split(',').join('');
          }

          let pmt = new Dollar(Number(value));
          let pmts = new Dollar(this.loan.totalDailyAchDebitPayments ?? 0);
          let dailyLimit = new Dollar(this.location.achDebitDailyLimit);
          let diff = dailyLimit.subtract(pmts);

          return (
            pmt.isLessThanOrEqualTo(diff) ||
            `Maximum payment allowed is ${currency(
              diff.toFloat(),
            )} before exceeding daily limit of ${currency(
              this.location.achDebitDailyLimit,
            )}.`
          );
        },
      },
      loadingPaymentMethods: false,
      paymentMethods: [],
    };
  },
  methods: {
    disableAchItem(item) {
      if (this.loan.achPaymentsDisabled && item.type == 2) {
        return true;
      }
      return false;
    },
    getPaymentMethods() {
      this.loadingPaymentMethods = true;
      paymentMethodService
        .getPaymentMethods(this.loan.loanNumber)
        .then((res) => {
          this.paymentMethods = res.data;
          if (this.paymentMethods && this.paymentMethods.length > 0)
            for (var i = 0; i < this.paymentMethods.length; i++) {
              var pm = this.paymentMethods[i];
              if (pm.isDefault && !this.disableAchItem(pm)) {
                // eslint-disable-next-line vue/no-mutating-props
                this.paymentInfo.paymentMethod = pm;
                break;
              }
            }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loadingPaymentMethods = false;
        });
    },
    appendPaymentMethod(paymentMethod) {
      if (paymentMethod) {
        this.paymentMethods.push(paymentMethod);
        // set as selected payment method
        // eslint-disable-next-line vue/no-mutating-props
        this.paymentInfo.paymentMethod = paymentMethod;
      }
    },
    paymentMethodRemoved(paymentMethodId) {
      var indexOf = this.paymentMethods.findIndex(
        (pm) => pm.paymentMethodId === paymentMethodId,
      );
      if (indexOf !== -1) {
        this.paymentMethods.splice(indexOf, 1);
      }
    },
    makeAPayment() {
      this.$emit('makePaymentClicked', this.paymentInfo);
    },
    getUpdatedDetails() {
      loanService
        .calculateDetails({
          loanNumber: this.loan.loanNumber,
          paymentAmount: this.paymentInfo.paymentAmount,
        })
        .then((res) => {
          // eslint-disable-next-line vue/no-mutating-props
          this.paymentInfo.interestAndFees = res.data.interestAndFees;
          // eslint-disable-next-line vue/no-mutating-props
          this.paymentInfo.principal = res.data.principal;
        });
    },
    cancel() {
      this.$emit('cancel');
    },
  },
  created() {
    if (!this.loan) {
      this.cancel();
    }

    this.getPaymentMethods();

    if (this.loan.defaultPaymentAmount && this.location.achDebitDailyLimit) {
      if (
        new Dollar(this.loan.defaultPaymentAmount).isGreaterThan(
          new Dollar(this.location.achDebitDailyLimit),
        )
      ) {
        // eslint-disable-next-line vue/no-mutating-props
        this.paymentInfo.paymentAmount = this.location.achDebitDailyLimit;
      } else {
        // eslint-disable-next-line vue/no-mutating-props
        this.paymentInfo.paymentAmount = this.loan.defaultPaymentAmount;
      }
      this.$nextTick(() => {
        let amt = this.$refs.amt.$el.querySelector('input');
        amt.select();
      });
    } else {
      // eslint-disable-next-line vue/no-mutating-props
      this.paymentInfo.paymentAmount = 0;
    }
  },
  directives: {
    focus: {
      inserted: function (el) {
        // A little timeout so that the focus animation
        // happens slightly after the view is loaded and can capture
        // the user's attention
        setTimeout(function () {
          // this is specific to the v-currency-field
          const childEl = el.getElementsByTagName('input')[0];
          childEl.focus();
        }, 300);
      },
    },
  },
  watch: {
    'paymentInfo.paymentAmount': function (val) {
      if (!val) {
        // eslint-disable-next-line vue/no-mutating-props
        this.paymentInfo.principal = 0;
        // eslint-disable-next-line vue/no-mutating-props
        this.paymentInfo.interestAndFees = 0;
        return;
      }

      let pmt = new Dollar(val);
      let interestAndFees = new Dollar(this.loan.interestAndFeesDue);

      if (pmt.isLessThanOrEqualTo(interestAndFees)) {
        // eslint-disable-next-line vue/no-mutating-props
        this.paymentInfo.interestAndFees = Number(pmt.toFloat());
        // eslint-disable-next-line vue/no-mutating-props
        this.paymentInfo.principal = 0;
        return;
      }

      let payoff = new Dollar(this.loan.payoffAmount);
      if (pmt.isGreaterThan(payoff)) {
        // eslint-disable-next-line vue/no-mutating-props
        this.paymentInfo.interestAndFees = this.loan.interestAndFeesDue;
        // eslint-disable-next-line vue/no-mutating-props
        this.paymentInfo.principal = Number(
          payoff.subtract(interestAndFees).toFloat(),
        );
        return;
      }

      // eslint-disable-next-line vue/no-mutating-props
      this.paymentInfo.interestAndFees = this.loan.interestAndFeesDue;
      let p = Number(pmt.subtract(interestAndFees).toFloat());
      // eslint-disable-next-line vue/no-mutating-props
      this.paymentInfo.principal = Number(
        pmt.subtract(interestAndFees).toFloat(),
      );

      // eslint-disable-next-line vue/no-mutating-props
      this.paymentInfo.principal = p;
    },
  },
};
</script>
