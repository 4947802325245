<template>
  <v-form ref="form" v-model="valid">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="550"
      :fullscreen="$vuetify.breakpoint.xs"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" color="primary">
          + Add Payment Method
        </v-btn>
      </template>
      <v-card>
        <div class="progress-container">
          <v-progress-linear
            v-if="showProgress"
            :indeterminate="true"
            style="margin: 0"
            color="accent"
          ></v-progress-linear>
        </div>
        <v-card-title> Enter Your New Payment Method </v-card-title>
        <v-card-text>
          <error-display :errors="errors"></error-display>
          <v-container fluid class="pa-0">
            <v-radio-group
              v-model="paymentMethod"
              v-if="!loan.achPaymentsDisabled"
              row
              mandatory
            >
              <v-radio
                v-for="pm in paymentMethodTypes"
                :key="pm.id"
                :value="pm.id"
                :label="pm.displayName"
                :disabled="showProgress || loan.achPaymentsDisabled"
              >
              </v-radio>
            </v-radio-group>
            <separator class="pb-2" v-if="!loan.achPaymentsDisabled" />

            <!-- DEBIT CARD -->
            <div v-if="paymentMethod === 1">
              <credit-card-field
                v-model="formattedCardNumber"
                v-on:raw="setRawValue"
                :autofocus="true"
                :disabled="showProgress"
              >
              </credit-card-field>
              <v-row>
                <v-col>
                  <month-picker
                    v-model="debitCard.expiration"
                    :label="'Exp. Date'"
                    :min="new Date().toISOString().substr(0, 10)"
                    :rules="expirationDateRules"
                    :disabled="showProgress"
                  >
                  </month-picker>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="debitCard.cvv"
                    label="CVV"
                    v-on:keypress="numericOnlyKeyPress"
                    maxlength="4"
                    :disabled="showProgress"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-col class="pa-0">
                <div>
                  <b>Enter Billing Address:</b>
                </div>
                <v-text-field
                  v-model="debitCard.streetAddress"
                  label="Street Address"
                  :rules="streetAddressRules"
                  required
                  :disabled="showProgress"
                  maxlength="200"
                ></v-text-field>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="debitCard.suiteApartmentNumber"
                      label="Ste/Apartment #"
                      :disabled="showProgress"
                      maxlength="10"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="debitCard.city"
                      label="City"
                      :rules="cityRules"
                      required
                      :disabled="showProgress"
                      maxlength="100"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <state-code-selector
                      v-model="debitCard.state"
                      :label="'State'"
                      :rules="stateRules"
                      required
                      :disabled="showProgress"
                    ></state-code-selector>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="debitCard.zipCode"
                      label="Zip Code"
                      :rules="zipCodeRules"
                      required
                      :disabled="showProgress"
                      maxlength="20"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </div>
            <div v-if="paymentMethod === 2">
              <v-text-field
                ref="routingNumber"
                v-model="ach.routingNumber"
                label="Routing Number"
                :error-messages="routingNumberErrors"
                v-on:keypress="numericOnlyKeyPress"
                :rules="[routingNumberRules.required, routingNumberRules.valid]"
                :disabled="showProgress || verifyingRoutingNumber"
                :loading="verifyingRoutingNumber"
                maxlength="9"
              ></v-text-field>
              <v-text-field
                v-model="ach.accountNumber"
                label="Account Number"
                v-on:keypress="numericOnlyKeyPress"
                :rules="accountNumberRules"
                :disabled="showProgress"
                type="number"
                maxlength="17"
              ></v-text-field>
              <v-text-field
                v-model="ach.confirmAccountNumber"
                label="Confirm Account Number"
                v-on:keypress="numericOnlyKeyPress"
                :rules="confirmAccountNumberRules"
                :disabled="showProgress"
                maxlength="17"
              ></v-text-field>
              <v-img src="../assets/check.jpg" class="mt-2 mb-2"></v-img>
            </div>
            <!-- -->
            <separator class="mt-2" />
            <div class="mt-4">
              <b>
                Would you like to store this payment method for future payments?
              </b>
            </div>
            <v-radio-group
              v-model="storePaymentOption"
              row
              mandatory
              class="mt-2 pl-3"
              :disabled="showProgress"
            >
              <v-radio :value="true" label="YES"> </v-radio>
              <v-radio :value="false" label="NO"> </v-radio>
            </v-radio-group>
            <div>
              <b>
                Would you like to set this payment method as your default
                payment option?
              </b>
            </div>
            <v-radio-group
              v-model="setAsDefaultPaymentOption"
              row
              mandatory
              class="mt-2 pl-3"
              :disabled="showProgress"
            >
              <v-radio :value="true" label="YES"> </v-radio>
              <v-radio :value="false" label="NO"> </v-radio>
            </v-radio-group>
          </v-container>
          <v-row class="pl-3 pr-3 pb-0 ta-j">
            By selecting "Agree and Add Account", you authorize the information
            you've provided on the above account to be used for creation of a
            charge to the account listed above. You also affirm that the
            information you provided is correct, that you are a signer on the
            account above and there are available funds to cover the amount of
            any transactions that you authorize.
          </v-row>
          <v-checkbox
            class="mt-2"
            label="Agree and Add Account"
            v-model="agreeAndAddAccount"
            :rules="[(v) => !!v || 'You must agree to continue']"
            required
            :disabled="showProgress"
          ></v-checkbox>
        </v-card-text>
        <v-card-actions class="border-top">
          <v-spacer></v-spacer>
          <v-btn
            color="primary darken-1"
            text
            @click="addPaymentMethod"
            :disabled="!valid || showProgress || verifyingRoutingNumber"
          >
            ADD PAYMENT METHOD
          </v-btn>
          <v-btn text @click="close" :disabled="showProgress"> CANCEL </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import CreditCardField from '../components/CreditCardField';
import StateCodeSelector from '../components/StateCodeSelector';
import MonthPicker from '../components/MonthPicker';
import ErrorDisplay from '../components/ErrorDisplay';
import { paymentMethodService } from '../services/paymentMethod.service';

export default {
  name: 'AddPaymentMethodDialog',
  components: {
    CreditCardField,
    MonthPicker,
    StateCodeSelector,
    ErrorDisplay,
  },
  props: ['loan'],
  data() {
    return {
      dialog: false,
      valid: false,
      showProgress: false,
      errors: [],
      routingNumberErrors: [],
      verifyingRoutingNumber: false,
      agreeAndAddAccount: false,
      setAsDefaultPaymentOption: true,
      storePaymentOption: true,
      loanNumber: this.loan.loanNumber,
      ach: {
        routingNumber: null,
        accountNumber: null,
        confirmAccountNumber: null,
      },
      debitCard: {
        cardNumber: null,
        expiration: null,
        cvv: null,
        streetAddress: null,
        suiteApartmentNumber: null,
        city: null,
        state: 'CA',
        zipCode: null,
      },
      formattedCardNumber: null,
      expirationDateRules: [
        (v) =>
          (!!v && this.paymentMethod === 1) || 'Expiration date is required',
      ],
      streetAddressRules: [
        (v) =>
          (!!v && this.paymentMethod === 1) || 'Street address is required',
      ],
      cityRules: [
        (v) => (!!v && this.paymentMethod === 1) || 'City is required',
      ],
      stateRules: [
        (v) => (!!v && this.paymentMethod === 1) || 'State is required',
      ],
      zipCodeRules: [
        (v) => (!!v && this.paymentMethod === 1) || 'Zip Code is required',
      ],
      routingNumberRules: {
        required: (v) =>
          (!!v && this.paymentMethod === 2) || 'Routing Number is required',
        valid: (v) =>
          (!!v && this.paymentMethod == 2 && v.length === 9) ||
          'Invalid routing number',
      },
      accountNumberRules: [
        (v) =>
          (!!v && this.paymentMethod === 2) || 'Account Number is required',
        (v) =>
          (!!v && this.paymentMethod == 2 && v.length > 4) ||
          'Invalid account number',
      ],
      confirmAccountNumberRules: [
        (v) =>
          (!!v && this.paymentMethod === 2) ||
          'Confirm Account Number is required',
        (v) =>
          (!!v && this.paymentMethod === 2 && v === this.ach.accountNumber) ||
          'Account numbers do not match',
      ],
      paymentMethod: 1,
      paymentMethodTypes: [
        {
          id: 1,
          displayName: 'Debit Card',
        },
        {
          id: 2,
          displayName: 'ACH',
        },
      ],
    };
  },
  methods: {
    verifyRoutingNumber() {
      let val = this.ach.routingNumber;
      if (!this.paymentMethod === 2 || !val || val.length !== 9) {
        if (this.routingNumberErrors.length > 0) {
          this.routingNumberErrors.splice(0, this.routingNumberErrors.length);
        }
        return;
      }
      this.verifyingRoutingNumber = true;
      this.routingNumberErrors.splice(0, this.routingNumberErrors.length);
      paymentMethodService
        .verifyRoutingNumber(val)
        .then((res) => {
          if (res.succeeded && !res.data) {
            this.routingNumberErrors.push('Invalid routing number');
          }
        })
        .finally(() => {
          this.verifyingRoutingNumber = false;
        });
    },
    numericOnlyKeyPress(e) {
      if (isNaN(parseInt(e.key))) {
        e.preventDefault();
        return false;
      }
    },
    resetForm() {
      this.errors.splice(0, this.errors.length);
      this.agreeAndAddAccount = false;
      this.cardNumber = null;
      this.debitCard.cardNumber = null;
      this.debitCard.expiration = null;
      this.debitCard.cvv = null;
      this.debitCard.streetAddress = null;
      this.debitCard.suiteApartmentNumber = null;
      this.debitCard.city = null;
      this.debitCard.state = null;
      this.debitCard.zipCode = null;
      this.ach.routingNumber = null;
      this.ach.accountNumber = null;
      this.ach.confirmAccountNumber = null;
      this.rawCardNumber = null;
      this.paymentMethod = 1;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    creditCardTypeChanged(type) {
      this.ccType = type;
    },
    setRawValue(val) {
      this.debitCard.cardNumber = val;
    },
    addPaymentMethod() {
      this.showProgress = true;
      let args = {
        isDefault: this.setAsDefaultPaymentOption,
        isVisible: this.storePaymentOption,
        loanNumber: this.loan.loanNumber,
        address1: this.debitCard.streetAddress,
        apt: this.debitCard.suiteApartmentNumber,
        stateCode: this.debitCard.state,
        city: this.debitCard.city,
        zipCode: this.debitCard.zipCode,
        type: this.paymentMethod,
        cardDetails: {
          debitCardNumber: this.debitCard.cardNumber,
          expirationMonth: null,
          expirationYear: null,
        },
        bankDetails: {
          bankRoutingNumber: this.ach.routingNumber,
          bankAccountNumber: this.ach.accountNumber,
        },
      };

      if (this.paymentMethod === 1) {
        var expiration = this.debitCard.expiration.split('/');
        args.cardDetails.expirationMonth = expiration[0];
        args.cardDetails.expirationYear = expiration[1];
      }

      paymentMethodService
        .addPaymentMethod(args)
        .then((res) => {
          if (!res.succeeded) {
            this.errors = res.errors;
          } else {
            this.errors.splice(0, this.errors.length);
            this.$emit('paymentMethodAdded', res.data);
            this.close();
          }
        })
        .catch((err) => {
          console.error(err);
          this.errors.push('Unable to add new payment method at this time.');
        })
        .finally(() => {
          this.showProgress = false;
        });
    },
    close() {
      this.resetForm();
      this.dialog = false;
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    dialog: function (n, o) {
      if (this.dialog) {
        this.resetForm();
      }
    },
    'ach.routingNumber': function (n) {
      this.verifyRoutingNumber(n);
    },
  },
};
</script>

<style lang="scss" scoped>
.border-top {
  border-top: solid px rgba(0, 0, 0, 0.12);
}
.progress-container {
  min-height: 7px;
  height: 7px;
}
</style>
