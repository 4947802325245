<template>
  <v-text-field
    :clearable="clearable"
    @click:clear="onClearClicked"
    v-model="formattedDate"
    label="Date of Birth"
    append-icon="event"
    :disabled="disabled"
    v-on:keypress="keyPress"
    hint="MM/DD/YYYY"
    :rules="rules"
  >
    <template v-slot:append>
      <v-dialog
        v-model="modal"
        ref="dialog"
        max-width="290"
        :return-value.sync="date"
        persistent
        :fullscreen="$vuetify.breakpoint.xs"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" small>
            <v-icon>mdi-calendar</v-icon>
          </v-btn>
        </template>
        <v-date-picker ref="picker" v-model="date" scrollable :max="max">
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
          <v-btn text color="primary" @click="$refs.dialog.save(date)">
            OK
          </v-btn>
        </v-date-picker>
      </v-dialog>
    </template>
  </v-text-field>
</template>

<script>
import Cleave from 'cleave.js';
export default {
  name: 'EditableDatePicker',
  props: {
    startWithYear: Boolean,
    max: [String, Date],
    min: [String, Date],
    disabled: Boolean,
    label: {
      type: String,
      default: 'Date of Birth',
    },
    rules: Array,
    clearable: Boolean,
  },
  data: () => {
    return {
      modal: false,
      formattedDate: null,
      date: null,
      cleave: null,
      option: {
        blocks: [2, 2, 4],
        delimiters: ['/'],
      },
    };
  },
  mounted() {
    const input = this.$el.querySelector('input');
    this.cleave = new Cleave(input, {
      ...this.option,
      onValueChanged: this.onValueChanged.bind(this),
    });
    this.cleave.setRawValue(this.value);
  },
  methods: {
    onClearClicked() {
      this.$nextTick(() => {
        this.$emit('input', null);
        this.$emit('raw', null);
      });
    },
    onValueChanged({ target }) {
      this.$nextTick(() => {
        this.$emit('input', target.value);
        this.$emit('raw', target.rawValue);
      });
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${month}/${day}/${year}`;
    },
    keyPress(e) {
      if (this.numeric && isNaN(e.key)) {
        e.preventDefault();
        return false;
      }
    },
  },
  watch: {
    modal(val) {
      if (!this.startWithYear) return;
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'));
    },
    // eslint-disable-next-line no-unused-vars
    date: function (val) {
      this.formattedDate = this.formatDate(this.date);
      this.$emit('input', this.formattedDate);
      this.$emit('raw', val);
    },
    formattedDate: function (val) {
      if (val === null) {
        this.date = null;
      }
    },
  },
};
</script>
