<template>
  <div>
    <v-col v-if="!loan.isArd">
      <name-value-pair-row
        name="Interest and Fees Due"
        :value="loan.interestAndFeesDue | currency"
        class="font-bold"
      ></name-value-pair-row>

      <v-row class="pl-3 pr-3 pb-3 justify-space-between font-bold">
        <v-flex>
          Catch Up to Payment Schedule
          <catch-up-to-payment-schedule-info-dialog></catch-up-to-payment-schedule-info-dialog>
        </v-flex>
        <v-flex class="ta-r">
          {{ loan.catchUpToPaymentSchedule | currency }}
        </v-flex>
      </v-row>

      <name-value-pair-row
        name="Payoff Amount"
        :value="loan.payoffAmount | currency"
        class="font-bold"
      ></name-value-pair-row>
      <name-value-pair-row
        name="Payment Due Date"
        :value="loan.paymentDueDate | formatDate"
        class="font-bold"
      ></name-value-pair-row>
      <name-value-pair-row
        v-if="!loan.isArd && loan.currentPaymentAmount"
        name="Loan Payment"
        :value="loan.currentPaymentAmount | currency"
        class="font-bold"
      ></name-value-pair-row>
    </v-col>
    <div v-if="loan.isArd && location" class="pb-4 font-bold">
      Because you have defaulted on your loan we are unable to provide you with
      your current balance information at this time. If you have already made
      payment arrangements, please make your payment(s) as agreed. If you need
      to make new or modify existing payment arrangements, please contact our
      Asset Recovery Department at {{ location.phone | formatPhone }}.
    </div>
  </div>
</template>

<script>
import NameValuePairRow from './NameValuePairRow';
import CatchUpToPaymentScheduleInfoDialog from '../dialogs/CatchUpToPaymentScheduleInfoDialog';
export default {
  name: 'LoanDetailBasicInfo',
  components: {
    NameValuePairRow,
    CatchUpToPaymentScheduleInfoDialog,
  },
  props: ['loan', 'location'],
  data() {
    return {
      show: false,
    };
  },
};
</script>
