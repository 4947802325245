<template>
  <v-select
    v-bind="$attrs"
    :items="states"
    item-text="name"
    item-value="code"
    :label="label"
    :rules="rules"
    :disabled="disabled"
    :loading="loading"
    v-bind:value="value"
    v-on:change="onValueChanged"
    :clearable="clearable"
  >
  </v-select>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'StateSelector',
  props: {
    rules: Array,
    disabled: Boolean,
    label: String,
    value: String,
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectedState: null,
      loading: false,
    };
  },
  created() {
    if (!this.statesLoaded) {
      this.loading = true;
      this.$store.dispatch('states/get').finally(() => {
        this.loading = false;
      });
    }
  },
  computed: {
    ...mapGetters({
      states: 'states/all',
      statesLoaded: 'states/loaded',
    }),
  },
  methods: {
    onValueChanged(target) {
      this.$emit('input', target);
    },
  },
};
</script>
