import axios from 'axios';
import { BaseService } from './base.service';

class StateService extends BaseService {
  static get Instance() {
    // Do you need arguments? Make it a regular method instead.
    return this.instance || (this.instance = new this());
  }
  get() {
    return axios
      .get(`${this.api}/states`)
      .then((res) => res.data)
      .catch((error) => this.handleError(error.response));
  }
}
// export a singleton instance in the global namespace
export const stateService = StateService.Instance;
