<template>
  <v-dialog v-model="dialog" max-width="480">
    <template v-slot:activator="{ on, attrs }">
      <v-btn x-small icon v-bind="attrs" v-on="on">
        <v-icon>mdi-information-outline</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="word-wrap-normal"
        >Catch Up to Payment Schedule</v-card-title
      >
      <v-card-text
        >After paying this amount, your balance will be back to matching your
        payment schedule, and if future payments are made on schedule, will
        payoff your loan according to your contract.</v-card-text
      >
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary accent-4" @click="dialog = false">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'CatchUpToPaymentScheduleInfoDialog',
  data() {
    return {
      dialog: false,
    };
  },
};
</script>
