<template>
  <v-form
    v-model="valid"
    ref="changePasswordForm"
    @keyup.native.enter="changePassword"
  >
    <v-card>
      <v-card-title class="headline">Change Password</v-card-title>
      <v-card-text>
        <error-display
          v-bind:error="error"
          v-bind:errors="errors"
          class="mb-3"
        />
        <v-text-field
          ref="current"
          label="Current Password"
          v-model="password"
          :rules="passwordRules"
          :type="'password'"
          required
          name="password"
          autocomplete="current-password"
        >
        </v-text-field>
        <v-text-field
          label="New Password"
          v-model="newPassword"
          type="password"
          :rules="passwordRules"
          required
          autocomplete="new-password"
        >
        </v-text-field>
        <v-text-field
          label="Confirm new password"
          v-model="confirmNewPassword"
          type="password"
          :rules="confirmPasswordRules"
          required
          autocomplete="new-password"
        >
        </v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary lighten-1"
          @click="changePassword"
          :disabled="!valid || isBusy"
        >
          Change My Password
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationRules } from '../mixins/validationRules';
import ErrorDisplay from '../components/ErrorDisplay';
export default {
  name: 'ChangePassword',
  mixins: [validationRules],
  components: {
    ErrorDisplay,
  },
  data: function () {
    return {
      error: null,
      errors: null,
      valid: false,
      isBusy: false,
      password: null,
      newPassword: null,
      confirmNewPassword: null,
      confirmPasswordRules: [
        (v) => !!v || 'Confirm password is required',
        (v) => v === this.newPassword || 'Passwords do not match',
      ],
    };
  },
  computed: mapGetters({
    token: 'auth/token',
  }),
  methods: {
    changePassword() {
      if (!this.valid) {
        return;
      }
      this.isBusy = true;
      this.error = null;
      this.errors = null;
      this.$store
        .dispatch('account/changePassword', {
          currentPassword: this.password,
          newPassword: this.newPassword,
        })
        .then((res) => {
          if (res.succeeded) {
            this.$store.commit(
              'snackbar/showSnack',
              'Password successfully changed',
            );
            this.password = null;
            this.newPassword = null;
            this.confirmNewPassword = null;
            this.$refs.changePasswordForm.reset();
            this.$nextTick(() => {
              this.$refs.current.focus();
            });
          } else {
            // show error
            if (res.errors.length > 1) {
              this.errors = res.errors;
            } else {
              this.error = res.errors[0];
            }
          }
        })
        .catch((err) => {
          this.error = err;
        })
        .then(() => {
          this.isBusy = false;
        });
    },
  },
};
</script>
