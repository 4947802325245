import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth';
import account from './modules/account';
import loan from './modules/loan';
import snackbar from './modules/snackbar';
import locations from './modules/locations';
import states from './modules/states';
import company from './modules/company';
import version from './modules/version';
import vault from './modules/vault';

Vue.use(Vuex);

var store = {
  state: {},
  mutations: {},
  actions: {
    clear: ({ state, commit }) => {
      Object.keys(state).forEach((m) => {
        commit(`${m}/clear`);
      });
    },
  },
  modules: {
    auth: {
      namespaced: true,
      state: auth.state,
      mutations: auth.mutations,
      getters: auth.getters,
      actions: auth.actions,
    },
    account: {
      namespaced: true,
      state: account.state,
      mutations: account.mutations,
      getters: account.getters,
      actions: account.actions,
    },
    loan: {
      namespaced: true,
      state: loan.state,
      mutations: loan.mutations,
      getters: loan.getters,
      actions: loan.actions,
    },
    snackbar: {
      namespaced: true,
      state: snackbar.state,
      mutations: snackbar.mutations,
      actions: snackbar.actions,
    },
    location: {
      namespaced: true,
      state: locations.state,
      mutations: locations.mutations,
      getters: locations.getters,
      actions: locations.actions,
    },
    states: {
      namespaced: true,
      state: states.state,
      mutations: states.mutations,
      getters: states.getters,
      actions: states.actions,
    },
    company: {
      namespaced: true,
      state: company.state,
      mutations: company.mutations,
      getters: company.getters,
      actions: company.actions,
    },
    version: {
      namespaced: true,
      state: version.state,
      mutations: version.mutations,
      getters: version.getters,
      actions: version.actions,
    },
    vault: {
      namespaced: true,
      state: vault.state,
      mutations: vault.mutations,
      getters: vault.getters,
      actions: vault.actions,
    },
  },
};

Object.keys(store.modules).forEach((m) => {
  if (!store.modules[m].mutations || !store.modules[m].mutations.clear) {
    throw new Error(`store module ${m} is missing clear`);
  }
});

export default new Vuex.Store(store);
