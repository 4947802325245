<template>
  <centered-content-area>
    <v-form v-model="valid">
      <v-card>
        <div class="progress-container">
          <v-progress-linear
            v-if="isBusy"
            :indeterminate="true"
            style="margin: 0"
            color="accent"
          ></v-progress-linear>
        </div>
        <v-card-text>
          <error-display :errors="errors" class="mb-3"></error-display>
          <div class="emphasis ta-c">
            <span>
              Enter Your Loan Information Below to Link Your Account:
            </span>
            <span v-if="failed && failedMessage1">
              {{ failedMessage1 }}
            </span>
          </div>
          <div class="mt-2 mb-3 ta-c">
            <span v-if="!failed">
              You must enter a minimum of 3 fields to link your loan.
            </span>
            <span v-if="failed && failedMessage2">
              {{ failedMessage2 }}
            </span>
          </div>
          <v-text-field
            label="Loan Number"
            v-model="linkArgs.loanNumber"
            autofocus
            maxlength="25"
            :disabled="isBusy"
          >
          </v-text-field>
          <masked-input
            :label="'Social Security Number'"
            :option="{ blocks: [3, 2, 4], delimiter: '-' }"
            v-model="formattedSsn"
            v-on:raw="setRawValue"
            maxlength="11"
            :numeric="true"
            :disabled="isBusy"
          ></masked-input>
          <v-text-field
            label="I.D. Number"
            v-model="linkArgs.idNumber"
            :disabled="isBusy"
          >
          </v-text-field>
          <date-picker
            :clearable="true"
            v-model="linkArgs.dateOfBirth"
            :max="maxDate"
            :min="'1920-01-01'"
            :startWithYear="true"
            :disabled="isBusy"
            :rules="[isValidDate]"
          ></date-picker>
          <masked-input
            :label="'Phone Number'"
            :option="{ blocks: [0, 3, 3, 4], delimiters: ['(', ') ', '-'] }"
            v-model="formattedPhone"
            v-on:raw="setRawPhoneValue"
            :numeric="true"
            :autocomplete="'tel'"
            :disabled="isBusy"
          ></masked-input>
        </v-card-text>
        <v-card-actions>
          <v-btn
            width="100%"
            color="secondary"
            @click="handleSubmit"
            :disabled="!trulyValid || isBusy"
          >
            Link My Loan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </centered-content-area>
</template>

<script>
import { mapGetters } from 'vuex';
import { isValid } from 'date-fns';
import MaskedInput from '../components/MaskedInput';
import CenteredContentArea from '../components/CenteredContentArea';
import DatePicker from '../components/DatePicker';
import ErrorDisplay from '../components/ErrorDisplay';

export default {
  name: 'LinkMyLoan',
  components: {
    MaskedInput,
    CenteredContentArea,
    DatePicker,
    ErrorDisplay,
  },
  data: () => {
    return {
      errors: [],
      menu: false,
      isBusy: false,
      valid: false,
      trulyValid: false,
      failed: false,
      failedMessage1: null,
      failedMessage2: null,
      formattedSsn: null,
      formattedPhone: null,
      dateOfBirthFormatted: null,
      dob: null,
      linkArgs: {
        loanNumber: null,
        ssn: null,
        dateOfBirth: null,
        idNumber: null,
        phoneNumber: null,
      },
    };
  },
  computed: {
    maxDate() {
      var date = new Date();
      date.setFullYear(date.getFullYear() - 18);
      return date.toISOString().substr(0, 10);
    },
    ...mapGetters({
      profile: 'account/profile',
      companyPhone: 'company/phoneNumber',
    }),
  },
  methods: {
    isValidDate(val) {
      if (!val) return true;
      if (val.length < 10) {
        return 'Invalid date';
      }
      let dt = new Date(val);
      if (!isValid(dt)) {
        return 'Invalid date';
      }
      if (dt > new Date()) {
        return 'Date cannot be a future date';
      }
      return true;
    },
    setRawValue(rawValue) {
      this.linkArgs.ssn = rawValue;
    },
    setRawPhoneValue(rawValue) {
      this.linkArgs.phoneNumber = rawValue;
    },
    hasAtLeastThree() {
      var ctr = 0;
      for (var prop in this.linkArgs) {
        if (Object.prototype.hasOwnProperty.call(this.linkArgs, prop)) {
          if (this.linkArgs[prop]) {
            ctr++;
          }
        }
      }
      return ctr >= 3;
    },
    handleSubmit() {
      if (!this.valid) {
        return;
      }
      this.errors = this.errors.splice(0, this.errors.length);
      this.isBusy = true;
      this.failed = false;
      let args = this.linkArgs;
      this.$store
        .dispatch('loan/link', {
          loanNumber: args.loanNumber,
          ssn: args.ssn,
          dateOfBirth: args.dateOfBirth,
          idNumber: args.idNumber,
          phoneNumber: args.phoneNumber,
        })
        .then((res) => {
          if (!res.succeeded) {
            this.failed = true;
            this.errors = res.errors;
          } else {
            if (res.data === null) {
              this.failed = true;
              this.failedMessage1 =
                'We are unable to find a loan based on the information you have entered.';
              this.failedMessage2 = `Please try again or contact customer service at ${this.companyPhone}.`;
              return;
            } else if (res.data === -1) {
              this.failed = true;
              this.failedMessage1 =
                'Your loan center has not been activated on the customer portal. We are currently implementing the customer portal in a phased rollout. Once your loan center has been activated, you will receive communication and be able to link your loan. Thank you.';
              return;
            }
            this.$store.commit(
              'snackbar/showSnack',
              'Loan successfully linked.',
            );
            this.$router.push('/loan-details');
          }
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    saveBirthDate(date) {
      this.$refs.menu.save(date);
    },
  },
  watch: {
    linkArgs: {
      deep: true,
      handler() {
        this.trulyValid = this.valid && this.hasAtLeastThree();
      },
    },
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'));
    },
  },
};
</script>

<style lang="scss" scoped>
.progress-container {
  min-height: 7px;
  height: 7px;
}
</style>
