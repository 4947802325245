import axios from 'axios';
import { BaseService } from './base.service';

class AccountService extends BaseService {
  static get Instance() {
    // Do you need arguments? Make it a regular method instead.
    return this.instance || (this.instance = new this());
  }
  register(userRegistration) {
    return axios
      .post(`${this.api}/account`, userRegistration)
      .then((res) => res.data)
      .catch((error) => this.handleError(error.response));
  }

  verify(args) {
    return axios
      .post(`${this.api}/account/verify-account`, args)
      .then((res) => res.data)
      .catch((error) => this.handleError(error.response));
  }

  resend(args) {
    return axios
      .post(`${this.api}/account/resend`, args)
      .then((res) => res.data)
      .catch((error) => this.handleError(error.response));
  }

  forgotPassword(args) {
    return axios
      .post(`${this.api}/account/forgot-password`, args)
      .then((res) => res.data)
      .catch((error) => this.handleError(error.response));
  }

  resetPassword(args) {
    return axios
      .post(`${this.api}/account/reset-password`, args)
      .then((res) => res.data)
      .catch((error) => this.handleError(error.response));
  }

  changePassword(args) {
    return axios
      .post(`${this.api}/account/change-password`, args)
      .then((res) => res.data)
      .catch((error) => {
        this.handleError(error.response);
      });
  }

  getProfile() {
    return axios
      .get(`${this.api}/account/profile`)
      .then((res) => res.data)
      .catch((error) => this.handleError(error.response));
  }
}
// export a singleton instance in the global namespace
export const accountService = AccountService.Instance;
