import axios from 'axios';
import { BaseService } from './base.service';

class LoanService extends BaseService {
  static get Instance() {
    // Do you need arguments? Make it a regular method instead.
    return this.instance || (this.instance = new this());
  }
  link(args) {
    return axios
      .post(`${this.api}/loan/link`, args)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        this.handleError(error.response);
      });
  }
  getLoans() {
    return axios
      .get(`${this.api}/loan/loans`)
      .then((res) => res.data)
      .catch((error) => {
        this.handleError(error.response);
      });
  }

  hasLinkedLoans() {
    return axios
      .get(`${this.api}/loan/has-linked-loans`)
      .then((res) => res.data)
      .catch((error) => {
        this.handleError(error.response);
      });
  }

  getLoan(loanNumber) {
    return axios
      .get(`${this.api}/loan/${loanNumber}`)
      .then((res) => res.data)
      .catch((error) => {
        this.handleError(error.response);
      });
  }

  getBorrowerInfo(loanNumber) {
    return axios
      .get(`${this.api}/loan/borrower-info/${loanNumber}`)
      .then((res) => res.data)
      .catch((error) => {
        this.handleError(error.response);
      });
  }

  getIncreaseTerms(loanType, min, max) {
    return axios
      .get(`${this.api}/loan/terms/${loanType}/${min}/${max}`)
      .then((res) => res.data)
      .catch((error) => {
        this.handleError(error.response);
      });
  }

  getIncreaseEligibleSliderRange(loanNumber) {
    return axios
      .get(`${this.api}/loan/increase-slider-range/${loanNumber}`)
      .then((res) => res.data)
      .catch((error) => {
        this.handleError(error.response);
      });
  }

  getMostRecentLoanLocationId() {
    return axios
      .get(`${this.api}/loan/most-recent-loan-location`)
      .then((res) => res.data)
      .catch((error) => {
        this.handleError(error.response);
      });
  }

  getPaymentHistory(loanNumber) {
    return axios
      .get(`${this.api}/loan/get-payment-history/${loanNumber}`)
      .then((res) => res.data)
      .catch((error) => {
        this.handleError(error.response);
      });
  }
}
// export a singleton instance in the global namespace
export const loanService = LoanService.Instance;
