import { loanService } from '../../services/loan.service';

const state = {
  loans: [],
};

const getters = {
  loans: (s) => s.loans,
};

const actions = {
  clear: ({ commit }) => {
    commit('clear');
  },
  getLoans: ({ commit }, userId) => {
    return new Promise((resolve, reject) => {
      loanService.getLoans(userId).then(
        (result) => {
          if (result.succeeded) {
            commit('addLoans', result.data);
          }
          resolve(result);
        },
        (errors) => {
          reject(errors);
        },
      );
    });
  },
  link: ({ commit, rootGetters }, args) => {
    return new Promise((resolve, reject) => {
      let isPresto = rootGetters['company/isPresto'];
      args.company = isPresto ? 'Presto' : 'Cashtime';
      loanService.link(args).then(
        (result) => {
          if (result.succeeded) {
            commit('addLoan', result.data);
          }
          resolve(result);
        },
        (errors) => {
          reject(errors);
        },
      );
    });
  },
};

const mutations = {
  addLoan(state, loan) {
    if (loan) {
      state.loans.push(loan);
    }
  },
  addLoans(state, loans) {
    state.loans = loans;
  },
  clear: (s) => {
    s.loan = null;
    s.loans.splice(0, s.loans.length);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
