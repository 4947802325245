import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import axios from 'axios';
import { VTextField } from 'vuetify/lib';
import VCurrencyField from 'v-currency-field';
import filters from './filters';
import Separator from './components/Separator.vue';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';
import './assets/scss/main.scss';
import './assets/scss/theme.scss';

Vue.config.productionTip = false;
Vue.component('v-text-field', VTextField);
// eslint-disable-next-line vue/multi-word-component-names
Vue.component('separator', Separator);
Vue.use(VCurrencyField, {
  locale: 'en-US',
  decimalLength: 2,
  autoDecimalMode: true,
  defaultValue: 0,
  allowNegative: false,
});

Vue.use(filters);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');

axios.interceptors.request.use(
  (config) => {
    const authToken = store.getters['auth/token'];
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken.auth_token}`;
    }
    return Promise.resolve(config);
  },
  (err) => {
    return Promise.reject(err);
  },
);

const UNAUTHORIZED = 401;
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    return new Promise((resolve, reject) => {
      const originalRequest = error.config;
      const { status } = error.response;
      if (status === UNAUTHORIZED && !originalRequest._retry) {
        originalRequest._retry = true;
        store
          .dispatch('auth/refresh')
          .then((res) => {
            if (!res.succeeded) {
              store.dispatch('auth/logOff').then(() => {
                store.commit(
                  'snackbar/showSnack',
                  'Invalid token. You have been automatically logged out.',
                );
                router.push('/login').catch(() => {});
              });
            } else {
              axios.request(originalRequest).then(resolve, reject);
            }
          })
          .catch((err) => {
            console.err('main', err);
          });
      } else {
        reject(error);
      }
    });
  },
);
