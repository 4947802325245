import axios from 'axios';
import { BaseService } from './base.service';

class PaymentMethodService extends BaseService {
  static get Instance() {
    // Do you need arguments? Make it a regular method instead.
    return this.instance || (this.instance = new this());
  }
  getPaymentMethods(loanNumber) {
    return axios
      .get(`${this.api}/payment-methods/${loanNumber}/false`)
      .then((res) => res.data)
      .catch((error) => {
        this.handleError(error.response);
      });
  }

  getClientPaymentMethods(loanNumber) {
    return axios
      .get(`${this.api}/payment-methods/client/${loanNumber}`)
      .then((res) => res.data)
      .catch((error) => {
        this.handleError(error.response);
      });
  }

  // getAchPaymentMethods(loanNumber) {
  //   return axios
  //     .get(`${this.api}/payment-methods/${loanNumber}/true`)
  //     .then(res => res.data)
  //     .catch(error => {
  //       this.handleError(error.response);
  //     });
  // }

  addPaymentMethod(args) {
    return axios
      .post(`${this.api}/payment-methods`, args)
      .then((res) => res.data)
      .catch((err) => {
        this.handleError(err.response);
      });
  }

  removePaymentMethod(paymentMethodId) {
    return axios
      .post(`${this.api}/payment-methods/remove/${paymentMethodId}`)
      .then((res) => res.data)
      .catch((err) => {
        this.handleError(err.response);
      });
  }

  verifyRoutingNumber(routingNumber) {
    return axios
      .get(`${this.api}/payment-methods/verify-routing/${routingNumber}`)
      .then((res) => res.data)
      .catch((error) => {
        this.handleError(error.response);
      });
  }
}
// export a singleton instance in the global namespace
export const paymentMethodService = PaymentMethodService.Instance;
