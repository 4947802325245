import { vaultService } from '../../services/vault.service';

const state = {
  key: null,
  loaded: false,
};

const getters = {
  key: (s) => s.key,
  loaded: (s) => s !== null,
};

const actions = {
  clear: ({ commit }) => {
    commit('clear');
  },
  get: ({ commit, state }) => {
    return new Promise((resolve, reject) => {
      if (state.loaded) {
        resolve();
      } else {
        vaultService.get().then(
          (result) => {
            if (result.succeeded) {
              commit('setKey', result.data);
            }
            resolve(result);
          },
          (errors) => {
            reject(errors);
          },
        );
      }
    });
  },
};

const mutations = {
  setKey(state, key) {
    state.key = key;
  },
  clear(state) {
    state.key = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
