<template>
  <v-card v-if="loan">
    <v-card-title>
      Title Loan:<span class="uppercase pl-1">
        {{ loan.loanNumber }}
      </span>
    </v-card-title>
    <v-card-text>
      <loan-detail-basic-info
        :loan="loan"
        :location="location"
      ></loan-detail-basic-info>
      <v-btn
        :disabled="
          loan.noCustomerPortalPaymentsAllowed ||
          hasPaymentsPending ||
          loan.currentLoanStatus === 'Closed' ||
          !location
        "
        block
        color="secondary"
        @click="handleMakeAPaymentClicked"
        >Make A Payment</v-btn
      >
      <v-dialog v-model="showDialog" max-width="350">
        <v-card>
          <v-card-title>Payment Limit</v-card-title>
          <v-card-text>
            Your accumulated payments for this day has reached or exceeded the
            daily limit. Please contact the location of your loan.
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" text @click="showDialog = false">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-alert
        v-if="hasPaymentsPending"
        type="warning"
        icon="warning"
        class="elevation-2 mt-2"
        border="top"
      >
        You have a pending {{ loan.pendingPaymentType }} payment that was made
        on {{ loan.pendingPaymentDate | formatDate }} for
        {{ loan.pendingPaymentAmount | currency }}. This payment must be posted
        before you can make any additional payments.
      </v-alert>
      <p
        v-if="loan.currentLoanStatus === 'Closed' && location"
        class="pt-2 font-bold color-red ta-c"
        style="font-size: 15px"
      >
        This loan is closed. If you have received an increase you must link your
        new loan number to see current balances and make a payment. Please call
        customer service at {{ location.phone | formatPhone }}.
      </p>
      <p
        v-if="
          loan.currentLoanStatus !== 'Closed' &&
          loan.noCustomerPortalPaymentsAllowed &&
          location
        "
        class="pt-2 font-bold color-red ta-c"
        style="font-size: 15px"
      >
        Unable to make a customer portal payment for this account. Please call
        customer service at {{ location.phone | formatPhone }}.
      </p>
      <separator class="pb-3 mt-4 mb-2" />
      <name-value-pair-row
        name="Loan Origination"
        :value="loan.loanOriginationDate | formatDate"
      ></name-value-pair-row>
      <name-value-pair-row
        v-if="!loan.isArd"
        name="Principal Balance"
        :value="loan.principalBalance | currency"
      ></name-value-pair-row>
      <name-value-pair-row
        name="Last Payment"
        :value="loan.lastPaymentAmount | currency"
      ></name-value-pair-row>
      <name-value-pair-row
        name="Last Payment Date"
        :value="loan.lastPaymentDate | formatDate"
      ></name-value-pair-row>
      <name-value-pair-row
        v-if="!loan.isArd"
        name="Maturity Date"
        :value="loan.maturityDate | formatDate"
      ></name-value-pair-row>
      <loan-history-dialog :loan="loan"></loan-history-dialog>
      <v-row align="center" justify="center">
        <v-col cols="10" class="font-small ta-c">
          * Information or transactions may take up to 1 business day to
          display.
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import NameValuePairRow from './NameValuePairRow';
import Separator from './Separator';
import LoanHistoryDialog from '../dialogs/LoanHistoryDialog';
import LoanDetailBasicInfo from './LoanDetailBasicInfo';
export default {
  name: 'LoanDetail',
  components: {
    NameValuePairRow,
    Separator,
    LoanHistoryDialog,
    LoanDetailBasicInfo,
  },
  props: ['loan'],
  methods: {
    setLocation() {
      this.location = this.getLocationById(this.loan.locationId);
    },
    handleMakeAPaymentClicked() {
      if (
        this.loan.totalDailyAchDebitPayments >= this.location.achDebitDailyLimit
      ) {
        this.showDialog = true;
      } else {
        this.goToMakeAPayment(this.loan);
      }
    },
    goToMakeAPayment() {
      this.$router.push({
        name: 'MakeAPayment',
        params: {
          loan: this.loan,
          location: this.location,
        },
      });
    },
  },
  created() {
    if (this.locationsLoaded) {
      this.setLocation();
    }
  },
  data() {
    return {
      location: null,
      showDialog: false,
    };
  },
  computed: {
    ...mapGetters({
      getLocationById: 'location/getLocationById',
      locationsLoaded: 'location/loaded',
    }),
    hasPaymentsPending() {
      return this.loan.pendingPaymentsCount !== null;
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    locationsLoaded: function () {
      if (!this.location) {
        this.setLocation();
      }
    },
  },
};
</script>
