<template>
  <v-card v-if="paymentInfo && loan">
    <v-card-title> Title Loan {{ loan.loanNumber }} </v-card-title>
    <v-card-text>
      <error-display :errors="errors"></error-display>
      <name-value-pair-row
        name="Payment Date"
        :value="paymentInfo.paymentDate | formatDate"
        class="font-bold"
      ></name-value-pair-row>
      <name-value-pair-row
        name="Posting Date"
        :value="paymentInfo.postingDate | formatDate"
        class="font-bold"
      ></name-value-pair-row>
      <name-value-pair-row
        name="Payment Amount"
        :value="paymentInfo.paymentAmount | currency"
        class="font-bold"
      ></name-value-pair-row>
      <div class="pl-4">
        <name-value-pair-row
          v-if="!loan.isArd"
          name="Interest and Fees"
          :value="paymentInfo.interestAndFees | currency"
        ></name-value-pair-row>
        <name-value-pair-row
          v-if="!loan.isArd"
          name="Principal"
          :value="paymentInfo.principal | currency"
        ></name-value-pair-row>
      </div>
      <name-value-pair-row
        name="Payment Method"
        :value="paymentInfo.paymentMethod.displayName"
        class="font-bold mt-3"
      ></name-value-pair-row>
      <div class="mt-1">
        By clicking the Verify Payment button I, {{ profile.firstName }}
        {{ profile.lastName }}, confirm that today,
        {{ paymentInfo.paymentDate | formatDate }}, I am authorizing a one-time
        debit from my {{ paymentInfo.paymentMethod.displayName }} in the amount
        of {{ paymentInfo.paymentAmount | currency }} USD to be remitted to
        {{ companyName }}. This debit will occur on or after
        {{ paymentInfo.postingDate | formatDate }}.
      </div>
    </v-card-text>
    <v-card-actions class="border-top pb-4 pt-4">
      <v-spacer></v-spacer>
      <v-btn
        color="secondary"
        class="mr-1"
        @click="submitPayment"
        :disabled="showProgress"
        >Verify Payment</v-btn
      >
      <v-btn @click="cancel">Cancel</v-btn>
    </v-card-actions>
    <separator />
    <div class="pa-4 ta-c v-card__text">
      Please call {{ companyPhoneNumber }} with any questions.
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import NameValuePairRow from './../NameValuePairRow';
import ErrorDisplay from '../ErrorDisplay';
export default {
  components: {
    NameValuePairRow,
    ErrorDisplay,
  },
  props: ['paymentInfo', 'loan', 'errors', 'showProgress'],
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    submitPayment() {
      this.$emit('submitPayment');
    },
  },
  computed: {
    companyName() {
      return this.companyId === 1 ? 'CashTime Title Loans' : 'Presto Loans';
    },
    ...mapGetters({
      profile: 'account/profile',
      companyPhoneNumber: 'company/phoneNumber',
      companyId: 'company/companyId',
    }),
  },
};
</script>
