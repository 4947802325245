import { companyService } from '../../services/company.service';

const state = {
  companyId: 1,
  company: null,
};

const getters = {
  loaded: (s) => !!s.company,
  isPresto: (s) => s.companyId === 5,
  companyId: (s) => s.companyId,
  email: (s) => s.company.email,
  name: (s) => s.company.name,
  phoneNumber: (s) => {
    if (
      !s.company ||
      !s.company.phoneNumbers ||
      s.company.phoneNumbers.length === 0
    ) {
      return '';
    }
    return s.company.phoneNumbers[0].phoneNumber;
  },
};

const actions = {
  clear: ({ commit }) => {
    commit('clear');
  },
  getCompanyById: ({ commit, state }, companyId) => {
    return new Promise((resolve, reject) => {
      if (state.loaded) {
        resolve();
      } else {
        companyService.get(companyId).then(
          (result) => {
            if (result.succeeded) {
              commit('setCompany', result.data);
            }
            resolve(result);
          },
          (errors) => {
            reject(errors);
          },
        );
      }
    });
  },
};

const mutations = {
  setIsPresto(state) {
    state.companyId = 5;
  },
  setCompany(state, data) {
    state.company = data;
    state.companyId = data.id;
  },
  clear: () => {
    // No need to clear anything
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
