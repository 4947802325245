<template>
  <centered-content-area>
    <v-card class="pa-4">
      <v-row class="pl-3 pr-3 pb-2 pt-1">
        <places-auto-complete
          v-on:changed="centerMapByPlaceId"
        ></places-auto-complete>
        <v-btn icon class="mt-2 ml-2" @click="getCurrentPosition">
          <v-icon> mdi-crosshairs-gps </v-icon>
        </v-btn>
      </v-row>
      <div
        id="map"
        style="width: 100%"
        v-bind:style="{ height: mapHeight }"
        class="mb-3"
      ></div>
      <div v-if="mapLoaded && computedLocations.length === 0" class="pa-4">
        We're sorry, We are unable to locate any loan centers in the selected
        area on the map. Reposition the map or zoom out to locate a loan center
        near you!
      </div>
      <!-- <div>
        {{ mapBounds }}
      </div> -->
      <div
        style="width: 100%"
        class="pl-3 pr-3 mt-3 pb-3 location-rows mt-5"
        v-if="mapLoaded && computedLocations.length > 0"
      >
        <v-row
          v-ripple
          v-bind:class="{
            selected:
              selectedLocation !== null &&
              location.locationId === selectedLocation.locationId,
          }"
          v-on:mouseover="mouseover(location)"
          v-on:mouseleave="mouseleave(location)"
          @click="goToLocation(location)"
          class="pointer location-row"
          v-for="location in computedLocations"
          :key="location.locationId"
        >
          <v-col cols="2" sm="1" class="align-self-center">
            <v-icon color="primary" large> mdi-map-marker </v-icon>
          </v-col>
          <v-col cols="10" sm="11" class="pb-1 pt-1">
            <v-row>
              <v-col cols="12" lg="6">
                <div style="color: rgb(0, 82, 166); font-weight: bold">
                  {{ location.publicLocationName }}
                </div>
                <div>{{ location.address1 }}</div>
                <div>
                  {{ location.city }}, {{ location.stateCode }}
                  {{ location.zipCode }}
                </div>
              </v-col>
              <v-col
                v-bind:class="{ 'ta-r': !$vuetify.breakpoint.mdAndDown }"
                cols="12"
                lg="6"
              >
                <v-fade-transition>
                  <div v-if="haveLocation">
                    {{ getDistance(location) }} miles
                  </div>
                </v-fade-transition>
                <v-flex>
                  {{ isOpen(location) }}: {{ formattedStoreHours(location) }}
                </v-flex>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </centered-content-area>
</template>

<script>
import { mapGetters } from 'vuex';
import CenteredContentArea from '../components/CenteredContentArea';
import PlacesAutoComplete from '../components/PlacesAutoComplete';
import { timeFuncs } from '../mixins/timeFuncs';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Locations',
  mixins: [timeFuncs],
  components: {
    CenteredContentArea,
    PlacesAutoComplete,
  },
  data() {
    return {
      map: null,
      searchText: null,
      coordinates: { latitude: 32.8559, longitude: -111.5548 }, // Coords for Eloy, AZ
      selectedLocation: null,
      mapLoaded: false,
      firstBoundsCheckComplete: false,
      haveLocation: false,
      timer: null,
      mapBounds: null,
      infoWindow: null,
      scriptLoaded: false,
    };
  },
  mounted() {
    this.loadGoogleMapsScript();
  },
  computed: {
    mapHeight() {
      if (this.$vuetify.breakpoint.mobile) {
        return '425px';
      }
      return '450px';
    },
    computedLocations() {
      var locations = [];
      if (!this.mapLoaded || !this.firstBoundsCheckComplete) return locations;
      for (var i = 0; i < this.locations.length; i++) {
        let l = this.locations[i];
        if (this.mapBounds) {
          if (this.mapBounds.contains(l.marker.getPosition())) {
            locations.push(l);
          }
        } else {
          locations.push(l);
        }
      }
      var prop = this.haveLocation ? 'distance' : 'zipCode';
      return locations.sort(function (a, b) {
        return parseFloat(a[prop]) - parseFloat(b[prop]);
      });
    },
    ...mapGetters({
      locations: 'location/locations',
      userPosition: 'location/userPosition',
      isPresto: 'company/isPresto',
    }),
  },
  methods: {
    initializeMap() {
      // eslint-disable-next-line no-undef
      this.infoWindow = new google.maps.InfoWindow();
      if (this.locations.length === 0) {
        this.$store.dispatch('location/getLocations').then(() => {
          this.initMap().then(() => {
            this.initPosition();
          });
        });
      } else {
        this.initMap().then(() => {
          this.initPosition();
        });
      }
    },
    loadGoogleMapsScript() {
      let t = this;
      return new Promise((resolve) => {
        var el = document.getElementById('gms');
        if (el) {
          t.initializeMap();
          return resolve();
        }
        let script = document.createElement('script');
        script.id = 'gms';
        script.setAttribute(
          'src',
          'https://maps.googleapis.com/maps/api/js?key=AIzaSyBM4n2TQgnlLMndabknG8ydKC22X23TnHQ&libraries=places,geometry',
        );
        script.async = true;

        script.onload = () => {
          t.scriptLoaded = true;
          resolve();
          t.initializeMap();
        };

        document.head.appendChild(script);
      });
    },
    formattedStoreHours(location) {
      var hours = this.getLocationHours(location, this.getCurrentArizonaDate());
      return hours.formattedStoreHours;
    },
    isOpen(location) {
      var d = this.getCurrentArizonaDate();
      var hours = this.getLocationHours(location, d);
      let h = d.getHours();
      let m = d.getMinutes();
      let o =
        h >= hours.openTime.hour &&
        m >= hours.openTime.minutes &&
        h <= hours.closeTime.hour &&
        m <= hours.closeTime.minutes;

      return o ? 'Open Now' : 'Hours';
    },
    getLocationHours(location, date) {
      var day = date
        .toLocaleDateString('en-US', { weekday: 'long' })
        .toLowerCase();
      return location[`${day}Hours`];
    },
    mouseover(location) {
      let m = location.marker;
      if (m.animated) return;
      // eslint-disable-next-line no-undef
      m.setAnimation(google.maps.Animation.BOUNCE);
      m.animated = true;
    },
    mouseleave(location) {
      let m = location.marker;
      m.setAnimation(null);
      m.animated = false;
    },
    initPosition() {
      this.hasGeoLocationPermission().then(() => {
        this.getCurrentPosition();
      });
    },
    getDistance(location) {
      // eslint-disable-next-line no-undef
      var latlng = new google.maps.LatLng(
        location.latitude,
        location.longitude,
      );

      var userCoords = this.userPosition ? this.userPosition : this.coordinates;
      // eslint-disable-next-line no-undef
      var coords = new google.maps.LatLng({
        lat: userCoords.latitude,
        lng: userCoords.longitude,
      });

      // eslint-disable-next-line no-undef
      var distance = google.maps.geometry.spherical.computeDistanceBetween(
        coords,
        latlng,
      );

      // distance is returned in meters, of which there are 1,609.344 per mile
      location.distance = (distance / 1609.344).toFixed(1);
      return location.distance;
    },
    getInfoWindowContent(location) {
      return `<div id="infoWindowContent-${location.locationId}">
      <div class="infowindow-container">
        <div id="infowindow-link-${location.locationId}" class="infowindow-link pointer">
          ${location.publicLocationName}
        </div>
        <div>${location.address1}</div>
        <div class="mb-3">
          ${location.city}, ${location.stateCode},
          ${location.zipCode}
        </div>
      </div>
    </div>`;
    },
    goToLocation(location) {
      this.$router.push({
        name: 'location',
        params: {
          locationId: location.locationId,
        },
      });
    },
    hasGeoLocationPermission() {
      return new Promise((resolve, reject) => {
        if (!navigator.permissions) reject();
        navigator.permissions
          .query({
            name: 'geolocation',
          })
          .then((permission) => {
            if (permission.state === 'granted') {
              resolve(true);
            }
          });
      });
    },
    getCurrentPosition() {
      this.$nextTick(() => {
        this.$store.dispatch('location/getCurrentPosition').then(() => {
          this.centerMapOnUser();
        });
      });
    },
    centerMapOnUser() {
      if (this.userPosition) {
        // eslint-disable-next-line no-undef
        var initialLocation = new google.maps.LatLng(
          this.userPosition.latitude,
          this.userPosition.longitude,
        );

        this.map.setCenter(initialLocation);
        this.map.setZoom(10);
        this.haveLocation = true;
      }
    },
    _on(opts) {
      var self = this;
      // eslint-disable-next-line no-undef
      google.maps.event.addListener(opts.obj, opts.event, function (e) {
        opts.callback.call(self, e);
      });
    },
    createMarker(opts) {
      // eslint-disable-next-line no-undef
      return new google.maps.Marker({
        // eslint-disable-next-line no-undef
        position: new google.maps.LatLng(opts.lat, opts.lng),
        map: this.map,
        location: opts.location,
      });
    },
    addMarker(opts) {
      var marker = this.createMarker(opts);
      var self = this;
      if (opts.events) {
        opts.events.forEach(function (event) {
          self._on({
            obj: marker,
            event: event.name,
            callback: event.callback,
          });
        });
      }
      return marker;
    },
    handleMarkerClick(location) {
      this.selectedLocation = location;
      var self = this;
      self.$nextTick(() => {
        var content = self.getInfoWindowContent(location);
        self.infoWindow.setContent(content);
        self.infoWindow.open(this.map, location.marker);
      });
    },
    addMarkers() {
      var self = this;
      for (var i = 0; i < this.locations.length; i++) {
        let l = this.locations[i];

        l.marker = this.addMarker({
          lat: l.latitude,
          lng: l.longitude,
          location: l,
          events: [
            {
              name: 'click',
              callback: () => {
                self.handleMarkerClick(l);
                // eslint-disable-next-line no-undef
                google.maps.event.addListener(
                  self.infoWindow,
                  'domready',
                  function () {
                    setTimeout(function () {
                      let id = `infowindow-link-${l.locationId}`;
                      let el = document.getElementById(id);
                      if (el) {
                        // eslint-disable-next-line no-undef
                        google.maps.event.addDomListener(
                          el,
                          'click',
                          function () {
                            self.goToLocation(l);
                          },
                        );
                      }
                    });
                  },
                );

                // eslint-disable-next-line no-undef
                google.maps.event.addListener(
                  self.infoWindow,
                  'closeclick',
                  function () {
                    l.marker.infoWindow = false;
                    if (
                      self.selectedLocation &&
                      l.locationId == self.selectedLocation.locationId
                    ) {
                      self.selectedLocation = null;
                    }
                  },
                );
              },
            },
          ],
        });
      }
    },
    boundsChanged() {
      this.mapBounds = this.map.getBounds();
    },
    centerMapByPlaceId(placeId) {
      if (!placeId) return;
      // eslint-disable-next-line no-undef
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ placeId: placeId }, (results, status) => {
        if (status !== 'OK') {
          // failed
          this.$store.commit('snackbar/showSnack', 'Unable to locate.');
          return;
        }

        let l = results[0].geometry.location;
        this.$store.commit('location/setUserPosition', {
          latitude: l.lat(),
          longitude: l.lng(),
        });
        this.map.setCenter(results[0].geometry.location);
        this.map.setZoom(10);
      });
    },
    initMap() {
      let self = this;
      return new Promise((resolve) => {
        this.$nextTick(() => {
          // eslint-disable-next-line no-undef
          self.map = new google.maps.Map(document.getElementById('map'), {
            center: {
              lat: this.coordinates.latitude,
              lng: this.coordinates.longitude,
            },
            // eslint-disable-next-line no-undef
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            zoom: 8,
          });

          // eslint-disable-next-line no-undef
          google.maps.event.addListenerOnce(this.map, 'idle', function () {
            self.mapLoaded = true;
          });

          this.map.addListener('bounds_changed', function () {
            if (self.timer) {
              clearTimeout(self.timer);
            }

            self.timer = setTimeout(() => {
              clearTimeout(self.timer);
              self.boundsChanged();
              self.firstBoundsCheckComplete = true;
            }, 250);
          });

          this.addMarkers();
          resolve();
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.selected {
  background-color: rgb(235, 235, 235);
}
.location-rows {
  border-left: solid 1px rgb(225, 225, 225);
  border-right: solid 1px rgb(225, 225, 225);
  border-bottom: solid 1px rgb(225, 225, 225);
}
.location-row {
  transition: background-color 250ms linear;
  border-top: solid 1px rgb(225, 225, 225);
  border-bottom: solid 1px transparent;
}
.location-row:hover {
  background-color: rgba(210, 210, 210, 0.2);
}
</style>

<style lang="scss">
.infowindow-container {
  overflow: initial !important;
  width: 100%;
  max-width: 100%;
  font-family: Roboto;
  font-weight: 300;
  font-size: 13px;
  .infowindow-link {
    margin: 0.2rem 0;
    font-size: 0.89rem;
    color: #0052a6;
    text-decoration: none !important;
    font-weight: 600;
    display: inline-block;
  }
}
</style>
