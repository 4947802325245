<template>
  <centered-content-area>
    <v-card v-if="!hideAll && !location">
      <v-card-title> Contact Us </v-card-title>
      <v-card-text class="pb-6">
        If you have questions, comments, or suggestions, please feel free to
        contact our Customer Service Department by phone or email.
        <div class="pt-3">
          <a :href="`tel:+${this.companyPhoneNumber}`" class="td-n">
            <v-icon class="primary--text pr-2">phone</v-icon>
            <span>{{ companyPhoneNumber }}</span>
          </a>
        </div>
        <div class="pt-3">
          <a v-bind:href="emailAddress" class="td-n">
            <v-icon class="primary--text pr-2">email</v-icon>
            <span>{{ companyEmail }}</span>
          </a>
        </div>
      </v-card-text>
    </v-card>
    <location-card
      v-if="!hideAll && location"
      :location="location"
    ></location-card>
  </centered-content-area>
</template>

<script>
import { mapGetters } from 'vuex';
import CenteredContentArea from '../components/CenteredContentArea';
import LocationCard from '../components/LocationCard';
import { loanService } from '../services/loan.service';
export default {
  name: 'ContactUs',
  components: {
    CenteredContentArea,
    LocationCard,
  },
  data() {
    return {
      hideAll: true,
      location: null,
    };
  },
  created() {
    this.$store.dispatch('location/getLocations');
    this.getLoanMostRecentLocation();
  },
  computed: {
    emailAddress() {
      return `mailto: ${this.companyEmail}`;
    },
    ...mapGetters({
      locations: 'location/locations',
      getLocationById: 'location/getLocationById',
      userPosition: 'location/userPosition',
      loans: 'loan/loans',
      companyEmail: 'company/email',
      companyPhoneNumber: 'company/phoneNumber',
    }),
  },
  methods: {
    getLoanMostRecentLocation() {
      loanService
        .getMostRecentLoanLocationId()
        .then((res) => {
          if (res.succeeded) {
            var location = this.getLocationById(res.data);
            if (!location.isArd) {
              this.location = location;
            }
          }
        })
        .finally(() => {
          this.hideAll = false;
        });
    },
  },
};
</script>
