<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="550"
    :fullscreen="$vuetify.breakpoint.xs"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn small v-bind="attrs" v-on="on"> Manage Payment Methods </v-btn>
    </template>
    <v-card>
      <v-card-title> Manage Payment Methods </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="paymentMethods"
          item-key="key"
          :items-per-page="200"
          hide-default-footer
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon v-if="item.paymentMethodId" @click="deleteItem(item)">
              mdi-delete
            </v-icon>
            <v-tooltip top v-else>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on"> mdi-cancel </v-icon>
              </template>
              <span>Contact your Loan Center to remove.</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <v-row class="mt-4 mr-1 mb-2">
          <v-spacer />
          <add-payment-method-dialog
            :loan="loan"
            v-on:paymentMethodAdded="appendPaymentMethod"
          ></add-payment-method-dialog>
        </v-row>
      </v-card-text>
      <v-card-actions class="border-top">
        <v-spacer />
        <v-btn text @click="close">CLOSE</v-btn>
      </v-card-actions>
    </v-card>
    <confirm-dialog ref="confirmDialog" />
  </v-dialog>
</template>

<script>
import AddPaymentMethodDialog from './AddPaymentMethodDialog.vue';
import ConfirmDialog from './ConfirmDialog.vue';
import { paymentMethodService } from '../services/paymentMethod.service';

export default {
  name: 'ManagePaymentMethodsDialog',
  props: ['paymentMethods', 'loan'],
  components: {
    AddPaymentMethodDialog,
    ConfirmDialog,
  },
  data() {
    return {
      dialog: false,
      showProgress: false,
      selected: [],
      headers: [
        {
          text: 'Name',
          sortable: true,
          value: 'displayName',
        },
        { text: 'Delete', value: 'actions', sortable: false },
      ],
    };
  },
  methods: {
    async deleteItem(item) {
      if (
        await this.$refs.confirmDialog.open(
          'Remove Payment Method?',
          'Payment method will be permanently removed from this loan.',
          'Remove',
        )
      ) {
        this.showProgress = true;
        // call service to remove item
        paymentMethodService
          .removePaymentMethod(item.paymentMethodId)
          .then((res) => {
            if (res.succeeded) {
              this.$store.commit(
                'snackbar/showSnack',
                'Payment method removed.',
                { isError: true },
              );
              item.isVisible = false;
              this.$emit('onPaymentMethodRemoved', item.paymentMethodId);
            } else {
              this.$store.commit(
                'snackbar/showSnack',
                'Unable to remove payment method at this time.',
                { isError: true },
              );
            }
          })
          .catch((err) => {
            console.error(err);
            this.$store.commit(
              'snackbar/showSnack',
              'Unable to remove payment method at this time.',
              { isError: true },
            );
          })
          .finally(() => {
            this.showProgress = false;
          });
      }
    },
    appendPaymentMethod(data) {
      this.$emit('paymentMethodAdded', data);
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>
