<template>
  <div class="account-master primary-bg">
    <v-slide-y-transition mode="out-in">
      <router-view />
    </v-slide-y-transition>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.account-master {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  background-size: cover;
  position: fixed;
  background-image: url('../../assets/header-bg.png');
  background-size: cover;
  background-position-x: center;
}
</style>
