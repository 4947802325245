<template>
  <v-dialog v-model="dialog" max-width="400" @keydown.esc="cancel">
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text>{{ message }}</v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click.native="cancel">Cancel</v-btn>
        <v-btn color="primary" text @click.native="confirm">{{
          actionButtonText
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmDialog',
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      title: null,
      message: null,
      actionButtonText: null,
    };
  },
  methods: {
    open(title, message, actionButtonText) {
      this.title = title;
      this.message = message;
      this.actionButtonText = actionButtonText;
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    confirm() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>
