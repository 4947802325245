import Vue from 'vue';
import VueRouter from 'vue-router';
import Shell from '../views/Shell.vue';
import Profile from '../views/profile/Profile.vue';
import AccountMaster from '../views/account/AccountMaster';
import LoginForm from '../views/account/LoginForm';
import RegistrationForm from '../views/account/RegistrationForm';
import Home from '../views/Home.vue';
import LinkMyLoan from '../views/LinkMyLoan.vue';
import LoanDetails from '../views/LoanDetails.vue';
import MakeAPayment from '../views/loan/MakeAPayment.vue';
import ApplyNow from '../views/ApplyNow';
import ContactUs from '../views/ContactUs';
import Locations from '../views/Locations';
import Location from '../views/Location';

import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Shell,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        name: 'Home',
        path: '/',
        component: Home,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/link-my-loan',
        component: LinkMyLoan,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/apply-now',
        component: ApplyNow,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/contact-us',
        component: ContactUs,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/locations',
        component: Locations,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/location/:locationId',
        name: 'location',
        component: Location,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'MakeAPayment',
        path: '/make-a-payment',
        component: MakeAPayment,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/profile',
        name: 'profile',
        component: Profile,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/loan-details',
        name: 'loanDetails',
        component: LoanDetails,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/increase/:loanNumber',
        name: 'increase',
        component: () =>
          import(
            /* webpackChunkName: "increaseEligible" */ '../views/loan/IncreaseEligible.vue'
          ),
        meta: {
          requiresAuth: true,
        },
      },
      // This is the endpoint that is registered with Plaid for a callback.
      // We'll know it's a callback by the passed param and that there is no loan #
      {
        path: '/increase',
        name: 'increaseRedirect',
        component: () =>
          import(
            /* webpackChunkName: "increaseEligible" */ '../views/loan/IncreaseEligible.vue'
          ),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '/account',
    component: AccountMaster,
    children: [
      {
        path: '/register',
        name: 'registrationForm',
        component: RegistrationForm,
      },
      {
        path: '/login',
        name: 'loginForm',
        component: LoginForm,
      },
      {
        path: '/forgot-password',
        name: 'forgotPassword',
        component: () =>
          import(
            /* webpackChunkName: "forgotPassword" */ '../views/account/ForgotPassword.vue'
          ),
      },
      {
        path: '/verify-account',
        name: 'verifyAccount',
        component: () =>
          import(
            /* webpackChunkName: "verifyAccount" */ '../views/account/VerifyAccount.vue'
          ),
      },
    ],
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.state.auth.token) {
      // Attempt to get a new token if user
      // has valid refresh token
      store
        .dispatch('auth/refresh')
        .then((res) => {
          if (res.succeeded) {
            store.dispatch('account/getProfile');
            next();
          } else {
            next({
              path: '/login',
              query: {
                redirect: to.fullPath,
              },
            });
          }
        })
        .catch((err) => {
          if (err === 'The server is not responding.') {
            store.commit('snackbar/showSnack', err);
          }
          next({
            path: '/login',
            query: {
              redirect: to.fullPath,
            },
          });
        });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
