<template>
  <v-img
    :width="width"
    :height="height"
    src="../assets/presto_logo.png"
  ></v-img>
</template>

<script>
export default {
  name: 'PrestoLogo',
  props: {
    height: {
      type: Number,
      default: 58,
    },
    width: {
      type: Number,
      default: 300,
    },
  },
};
</script>
