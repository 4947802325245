export class BaseService {
  constructor() {
    this.api = process.env.VUE_APP_API;
  }

  handleError(error) {
    if (!error) {
      return Promise.reject('The server is not responding.');
    }
    const applicationError = error.headers['Application-Error'];
    if (applicationError) {
      return Promise.reject(applicationError);
    }

    if (error.status === 400) {
      let modelStateErrors = '';
      if (error.data) {
        for (const key in error.data) {
          if (error.data[key]) {
            modelStateErrors += error.data[key] + '\n';
          }
        }
      }
      modelStateErrors = modelStateErrors === '' ? null : modelStateErrors;

      return Promise.reject(new Error(modelStateErrors || 'Server error'));
    }

    console.error('Server Error', error);
    return Promise.reject('A server error has occurred.');
  }
}
