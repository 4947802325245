<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="480"
    :fullscreen="$vuetify.breakpoint.xs"
    scrollable
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn text block color="primary" v-bind="attrs" v-on="on">
        VIEW LOAN HISTORY
      </v-btn>
    </template>
    <v-card>
      <v-toolbar style="z-index: 999" max-height="60px" dark color="primary">
        <v-toolbar-title>*Loan History ({{ loan.loanNumber }})</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pa-0" style="height: 400px">
        <v-expansion-panels accordion flat>
          <v-expansion-panel
            v-for="item in items"
            :key="item.id"
            class="border-top"
          >
            <v-expansion-panel-header expand-icon="mdi-menu-down">
              <v-row class="justify-space-between">
                <v-col cols="6" class="pa-0 pt-1"
                  >Payment ({{ item.manualOrAuto }})</v-col
                >
                <v-col cols="3" class="pa-0 pt-1">
                  {{ item.date | formatDate('MM/dd/yy') }}
                </v-col>
                <v-col cols="3" class="pa-0 pt-1 ta-r pr-6">{{
                  item.paymentAmount | currency
                }}</v-col>
              </v-row>
              <template v-slot:actions>
                <v-icon color="primary"> $expand </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pr-6 pl-6 pwb">
              <v-col class="pt-0 pb-0">
                <name-value-pair-row
                  name="Principal"
                  :value="item.principal | currency"
                ></name-value-pair-row>
                <name-value-pair-row
                  name="Interest"
                  :value="item.interest | currency"
                ></name-value-pair-row>
                <name-value-pair-row
                  name="Fees"
                  :value="item.fees | currency"
                ></name-value-pair-row>
              </v-col>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="elevation-3" style="z-index: 998">
        <v-spacer></v-spacer>
        <v-btn text @click="close" color="primary"> CLOSE </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import NameValuePairRow from '../components/NameValuePairRow';
import { loanService } from '../services/loan.service';
export default {
  name: 'LoanHistoryDialog',
  props: ['loan'],
  components: {
    NameValuePairRow,
  },
  data() {
    return {
      dialog: false,
      loaded: false,
      showProgress: false,
      items: [],
    };
  },
  methods: {
    close() {
      this.dialog = false;
      this.page = 1;
      this.loaded = false;
      this.items.splice(0, this.items.length);
    },
    getPaymentHistory() {
      this.showProgress = true;
      loanService
        .getPaymentHistory(this.loan.loanNumber)
        .then((res) => {
          this.items = res.data;
          this.loaded = true;
        })
        .catch((err) => {
          // TODO: handle error
          console.error(err);
        })
        .finally(() => {
          this.showProgress = false;
        });
    },
  },
  watch: {
    dialog: {
      handler(newVal) {
        if (newVal) {
          this.getPaymentHistory();
        }
      },
    },
  },
};
</script>

<style lang="scss">
.pwb {
  .v-expansion-panel-content__wrap {
    padding-bottom: 0 !important;
    padding-top: 8px;
  }
}
.border-top {
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}
</style>
