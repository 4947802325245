<template>
  <v-row class="pl-3 pr-3 pb-3 justify-space-between">
    <v-flex class="flex-wrap flex-grow-0">
      {{ name }}
      <slot name="name-append"></slot>
    </v-flex>
    <v-flex class="ta-r">
      <animated-text v-if="animateValue" :text="value"></animated-text>
      <span v-else>
        {{ value }}
      </span>
    </v-flex>
  </v-row>
</template>

<script>
import AnimatedText from './AnimatedText.vue';
export default {
  name: 'TwoColumnRow',
  props: {
    name: String,
    value: String,
    animateValue: Boolean,
  },
  components: {
    AnimatedText,
  },
};
</script>
