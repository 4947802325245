import axios from 'axios';
import { BaseService } from './base.service';

class PaymentService extends BaseService {
  static get Instance() {
    // Do you need arguments? Make it a regular method instead.
    return this.instance || (this.instance = new this());
  }

  makePayment(payment) {
    return axios
      .post(`${this.api}/payment`, payment)
      .then((res) => res.data)
      .catch((error) => {
        this.handleError(error.response);
      });
  }
}
// export a singleton instance in the global namespace
export const paymentService = PaymentService.Instance;
