import { accountService } from '../../services/account.service';

const state = {
  error: null,
  profile: null,
  registrationName: null,
  registrationEmail: null,
};

const getters = {
  authError: (s) => s.error,
  profile: (s) => s.profile,
  registrationName: (s) => s.registrationName,
  registrationEmail: (s) => s.registrationEmail,
};

const actions = {
  clear: ({ commit }) => {
    commit('clear');
  },
  getProfile: ({ commit, state }) => {
    return new Promise((resolve, reject) => {
      if (state.profile) {
        resolve();
      } else {
        accountService.getProfile().then(
          (result) => {
            commit('profile', result.data);
            resolve(result);
          },
          (errors) => {
            reject(errors);
          },
        );
      }
    });
  },

  forgotPassword: ({ commit }, args) => {
    return new Promise((resolve, reject) => {
      accountService.forgotPassword(args).then(
        (result) => {
          // eslint-disable-next-line no-prototype-builtins
          if (result.hasOwnProperty('succeeded') && !result.succeeded) {
            commit('authError', result.errors[0]);
          }
          resolve(result);
        },
        (errors) => {
          commit('authError', errors);
          reject(errors);
        },
      );
    });
  },

  // eslint-disable-next-line no-unused-vars
  resetPassword: ({ commit }, args) => {
    return new Promise((resolve, reject) => {
      accountService.resetPassword(args).then(
        (result) => {
          resolve(result);
        },
        (errors) => {
          reject(errors);
        },
      );
    });
  },

  // eslint-disable-next-line no-unused-vars
  changePassword: ({ commit }, args) => {
    return new Promise((resolve, reject) => {
      accountService.changePassword(args).then(
        (result) => {
          resolve(result);
        },
        (errors) => {
          reject(errors);
        },
      );
    });
  },
};

const mutations = {
  clear: (s) => {
    s.profile = null;
    s.error = null;
    s.registrationName = null;
    s.registrationEmail = null;
  },
  authError: (state, error) => {
    state.error = error;
  },
  profile: (state, profile) => {
    state.profile = profile;
  },
  registrationName: (state, name) => {
    state.registrationName = name;
  },
  registrationEmail: (state, email) => {
    state.registrationEmail = email;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
