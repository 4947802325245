<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <change-password></change-password>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ChangePassword from '../../components/ChangePassword';
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Profile',
  components: {
    ChangePassword,
  },
};
</script>
