<template>
  <v-card v-if="location">
    <v-card-title class="pb-1 word-wrap-normal">
      {{ location.webHeader }}
    </v-card-title>
    <v-card-text class="pb-0">
      <v-row>
        <v-col class="pt-1 pb-0">
          <div>{{ location.address1 }}</div>
          <div>
            {{ location.city }}, {{ location.stateCode }}
            {{ location.zipCode }}
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters style="font-size: 18px" class="pt-1 pb-1">
        <v-col cols="12" sm="6" class="primary--text pt-2 pb-2">
          <a :href="`tel:+${location.phone}`" class="td-n">
            <v-icon class="primary--text pr-2">phone</v-icon>
            <span>{{ location.phone | formatPhone }}</span>
          </a>
        </v-col>
        <v-col class="pt-2 pb-2" v-bind:class="{ 'ta-r': !isXs }">
          <a :href="directionsLink" target="_blank" class="td-n">
            <v-icon class="primary--text">directions</v-icon>
            Get Directions
          </a>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-text>
      <v-row>
        <v-col cols="12" lg="6" v-if="!isPresto">
          <v-img :src="getLocationImage(location)">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="blue lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-col>
        <v-col cols="12" lg="6">
          <h3>Store Hours</h3>
          <v-col class="pl-2">
            <name-value-pair-row
              name="Monday"
              :value="location.mondayHours.formattedStoreHours"
            ></name-value-pair-row>
            <name-value-pair-row
              name="Tuesday"
              :value="location.tuesdayHours.formattedStoreHours"
            ></name-value-pair-row>
            <name-value-pair-row
              name="Wednesday"
              :value="location.wednesdayHours.formattedStoreHours"
            ></name-value-pair-row>
            <name-value-pair-row
              name="Thursday"
              :value="location.thursdayHours.formattedStoreHours"
            ></name-value-pair-row>
            <name-value-pair-row
              name="Friday"
              :value="location.fridayHours.formattedStoreHours"
            ></name-value-pair-row>
            <name-value-pair-row
              name="Saturday"
              :value="location.saturdayHours.formattedStoreHours"
            ></name-value-pair-row>
            <name-value-pair-row
              name="Sunday"
              :value="location.sundayHours.formattedStoreHours"
            ></name-value-pair-row>
          </v-col>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-text>
      <h4 class="font-bold pb-1">Driving Directions</h4>
      <p>{{ location.drivingDirections }}</p>
      <div v-html="location.locationDescription"></div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import NameValuePairRow from '../components/NameValuePairRow';
export default {
  name: 'LocationCard',
  props: ['location'],
  components: {
    NameValuePairRow,
  },
  methods: {
    getLocationImage(location) {
      return `https://cashtimecdn.azureedge.net/cashtimecdn/images/${location.locationId}.jpg`;
    },
    getEscapedAddress(location) {
      return encodeURI(
        `${location.address1}, ${location.city}, ${location.stateCode}`,
      );
    },
    getOrigin() {
      return this.userPosition
        ? `&origin=${this.userPosition.latitude},${this.userPosition.longitude}`
        : '';
    },
  },
  computed: {
    ...mapGetters({
      userPosition: 'location/userPosition',
      isPresto: 'company/isPresto',
    }),
    isXs() {
      return this.$vuetify.breakpoint.xs;
    },
    directionsLink() {
      return `https://www.google.com/maps/dir/?api=1&destination=${this.getEscapedAddress(
        this.location,
      )}${this.getOrigin()}`;
    },
  },
};
</script>
