<template>
  <v-dialog v-model="dialog" max-width="290" persistent overlay-opacity="0.98">
    <v-card>
      <v-card-title> New Version </v-card-title>
      <v-card-text>
        A new version of the customer portal is available and your browser needs
        to be refreshed.
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" width="100%" @click="refresh"> Refresh </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { versionService } from '../services/version.service';

export default {
  data: () => {
    return {
      dialog: false,
      buttonDisabled: false,
    };
  },
  computed: {
    ...mapGetters({
      clientVersion: 'version/clientVersion',
    }),
  },
  methods: {
    refresh() {
      this.buttonDisabled = true;
      setTimeout(() => {
        window.location.reload(true);
      }, 100);
    },
    startPolling() {
      setTimeout(() => {
        // make server call to compare versions
        // don't continue polling if server call fails for any reason.
        versionService
          .getServerScriptVersion()
          .then((res) => {
            if (res.succeeded) {
              var serverVersion = res.data;
              var clientVersion = this.clientVersion;
              if (
                serverVersion !== null &&
                clientVersion !== null &&
                serverVersion !== clientVersion
              ) {
                this.dialog = true;
              } else {
                this.startPolling();
              }
            } else {
              this.startPolling();
            }
          })
          .catch((err) => {
            console.error(err);
            this.startPolling();
          });
      }, 1000 * 60);
    },
    getAppScriptHash() {
      var scripts = document.getElementsByTagName('script');
      for (var i = 0; i < scripts.length; i++) {
        var item = scripts[i];
        var index = item.src.indexOf('/js/app');
        if (index > 0) {
          var path = item.src.substring(index);
          // determine if in dev
          if (path !== '/js/app.js') {
            return path.substring(
              '/js/app'.length + 1,
              path.length - '.js'.length,
            );
          }
        }
      }
      return null;
    },
  },
  created() {
    var hash = this.getAppScriptHash();
    // If we're unable to get the client-side hash
    // there's no need to start polling
    if (hash !== null) {
      // add to store and start long polling
      this.$store.commit('version/setVersion', hash);
      this.startPolling();
    }
  },
};
</script>
