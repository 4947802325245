<template>
  <span>
    <v-fade-transition mode="out-in">
      <span v-if="primaryNode" :key="1">
        {{ text }}
      </span>
      <span v-if="!primaryNode">
        {{ text }}
      </span>
    </v-fade-transition>
  </span>
</template>

<script>
export default {
  name: 'AnimatedText',
  data() {
    return {
      primaryNode: true,
    };
  },
  props: {
    text: String,
  },
  watch: {
    text: function () {
      this.primaryNode = !this.primaryNode;
    },
  },
};
</script>
