<template>
  <account-card>
    <template v-slot:progress>
      <v-progress-linear
        v-if="isBusy"
        :indeterminate="true"
        style="margin: 0"
        color="accent"
      ></v-progress-linear>
    </template>
    <template v-slot:default>
      <div class="title">CREATE YOUR ACCOUNT</div>
      <error-display v-bind:errors="errors" />
      <v-form v-model="valid" @keyup.native.enter="handleSubmit">
        <v-text-field
          label="First name"
          v-model="user.firstName"
          :rules="firstNameRules"
          required
          autofocus
        >
        </v-text-field>
        <v-text-field
          label="Last name"
          v-model="user.lastName"
          :rules="lastNameRules"
          required
        >
        </v-text-field>
        <v-text-field
          label="Email"
          :type="'email'"
          v-model="user.email"
          :rules="emailRules"
          required
          autocomplete="username"
        >
        </v-text-field>
        <v-text-field
          label="Password"
          v-model="user.password"
          type="password"
          :rules="passwordRules"
          required
          autocomplete="new-password"
          hint="Must be at least 8 characters long, contain at least one uppercase character, at least one numeric character and at least one special character"
        >
        </v-text-field>
        <v-text-field
          label="Confirm password"
          v-model="confirmPassword"
          type="password"
          :rules="confirmPasswordRules"
          required
          autocomplete="new-password"
        >
        </v-text-field>
        <v-btn
          class="mt-3"
          color="secondary"
          @click="handleSubmit"
          :disabled="!valid || isBusy"
        >
          CREATE ACCOUNT
        </v-btn>
      </v-form>
      <div class="login">
        <span>Already have an account?</span>
        <router-link to="/login">Login</router-link>
      </div>
    </template>
  </account-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { accountService } from '../../services/account.service';
import AccountCard from './AccountCard';
import ErrorDisplay from '../../components/ErrorDisplay';
import { validationRules } from '../../mixins/validationRules';

export default {
  name: 'RegistrationForm',
  components: {
    AccountCard,
    ErrorDisplay,
  },
  mixins: [validationRules],
  data: function () {
    return {
      errors: [],
      valid: false,
      isBusy: false,
      user: {
        email: null,
        password: null,
        firstName: null,
        lastName: null,
        companyId: null,
      },
      confirmPassword: '',
      confirmPasswordRules: [
        (v) => !!v || 'Confirm password is required',
        (v) => v === this.user.password || 'Passwords do not match',
      ],
    };
  },
  methods: {
    handleSubmit() {
      if (!this.valid) {
        return;
      }
      this.isBusy = true;
      if (this.errors.length > 0) {
        this.errors.splice(0, this.errors.length);
      }
      this.user.companyId = this.companyId;
      accountService
        .register(this.user)
        .then(
          (res) => {
            if (res.succeeded) {
              this.$store.commit(
                'account/registrationName',
                this.user.firstName,
              );
              this.$store.commit('account/registrationEmail', this.user.email);
              this.$router.push({
                name: 'verifyAccount',
              });
            } else {
              // parse errors
              this.errors = res.errors;
            }
          },
          (error) => this.errors.push(error),
        )
        .finally(() => {
          this.isBusy = false;
        });
    },
  },
  computed: {
    ...mapGetters({
      companyId: 'company/companyId',
    }),
  },
};
</script>

<style scoped>
v-btn {
  top: 10px;
}
.title {
  font-size: 20px;
  margin: 16px 0 16px;
  font-weight: normal;
}
.login {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  max-width: 100%;
  align-items: center;
  margin: 32px auto 24px;
}
a,
.login > span {
  font-weight: 500;
}
a {
  text-decoration: none;
  font-size: 15px;
}
a:hover {
  text-decoration: underline;
}
.error-container {
  min-height: 33px;
  margin-bottom: 5px;
  position: relative;
}
.error {
  padding: 6px;
  color: #fff;
  border-radius: 2px;
}
</style>
