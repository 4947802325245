<template>
  <v-text-field
    label="Enter Card #"
    v-bind:value="creditCardNumber"
    v-on:keypress="keyPress"
    :rules="[cardNumberRules.required, verifyDebitCardNumber]"
    :autofocus="autofocus"
    append-icon="credit_card"
    :disabled="disabled"
  ></v-text-field>
</template>

<script>
import Cleave from 'cleave.js';
import Luhn from '../lib/luhn';

export default {
  name: 'CreditCardField',
  props: ['autofocus', 'disabled'],
  data() {
    return {
      cleave: null,
      rawCreditCardNumber: null,
      creditCardType: null,
      creditCardNumber: null,
      option: {
        creditCard: true,
        onCreditCardTypeChanged: function (val) {
          this.creditCardTypeChanged(val);
        },
      },
      cardNumberRules: {
        required: (v) => !!v || 'Card Number is required',
      },
    };
  },
  methods: {
    verifyDebitCardNumber(val) {
      if (!val) return true;
      let stripped = val.split(' ').join('');
      return Luhn.isValid(stripped) || 'Invalid Card Number';
    },
    creditCardTypeChanged(newType) {
      this.creditCardType = newType;
    },
    keyPress(e) {
      if (isNaN(e.key)) {
        e.preventDefault();
        return false;
      }
    },
    onValueChanged({ target }) {
      this.$nextTick(() => {
        this.$emit('input', target.value);
        this.$emit('raw', target.rawValue);
      });
    },
  },
  mounted() {
    const input = this.$el.querySelector('input');
    this.cleave = new Cleave(input, {
      creditCard: true,
      onCreditCardTypeChanged: this.creditCardTypeChanged,
      onValueChanged: this.onValueChanged.bind(this),
    });
    this.cleave.setRawValue(this.value);
  },
  beforeDestroy() {
    if (this.cleave) {
      this.cleave.destroy();
      this.cleave = null;
    }
  },
};
</script>
