import { locationsService } from '../../services/locations.service';
import { EventBus } from '../../event-bus';

const state = {
  all: [],
  ard: [],
  userPosition: null,
  loaded: false,
  loading: false,
};

const getters = {
  locations: (s) => s.all,
  userPosition: (s) => s.userPosition,
  loaded: (s) => s.loaded,
  getLocationById: (state) => (id) => {
    var location = state.all.find((l) => l.locationId === id);
    if (!location) {
      location = state.ard.find((l) => l.locationId === id);
    }
    return location;
  },
};

const actions = {
  clear: ({ commit }) => {
    commit('clear');
  },
  getCurrentPosition: ({ commit }) => {
    return new Promise((resolve, reject) => {
      if (!navigator || !navigator.geolocation) {
        commit('snackbar/showSnack', 'Geolocation is not available.', {
          root: true,
        });
        return;
      }
      navigator.geolocation.getCurrentPosition(
        function (pos) {
          commit('setUserPosition', {
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude,
          });
          resolve();
        },
        (err) => {
          var msg = '';
          switch (err.code) {
            case 1:
              msg = 'Unable to acquire position. Permission was denied';
              break;
            case 2:
              msg = 'Position unavailable.';
              break;
            case 3:
              msg = 'Unable to acquire position. Operation timed out.';
              break;
            default:
              msg = 'Unable to acquire position.';
          }
          commit('snackbar/showSnack', msg, { root: true });
          reject();
        },
        { enableHighAccuracy: true },
      );
    });
  },
  getLocations: ({ commit, state }) => {
    return new Promise((resolve, reject) => {
      if (state.loaded || state.loading) {
        resolve();
      } else {
        state.loading = true;
        locationsService
          .get()
          .then(
            (result) => {
              if (result.succeeded) {
                EventBus.$emit('locations_loaded');
                commit('allLocations', result.data);
              }
              resolve(result);
            },
            (errors) => {
              reject(errors);
            },
          )
          .finally(() => {
            state.loading = false;
          });
      }
    });
  },
};

const mutations = {
  setUserPosition(state, pos) {
    state.userPosition = pos;
  },
  allLocations(state, locations) {
    for (var i = 0; i < locations.length; i++) {
      let l = locations[i];
      if (l.isArd) {
        state.ard.push(l);
      } else {
        state.all.push(l);
      }
    }
    state.loaded = true;
  },
  clear: (s) => {
    s.loan = null;
    s.userPosition = null;
    // No need to clear locations
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
