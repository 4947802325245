import { authService } from '../../services/auth.service';
import { EventBus } from '../../event-bus';

const state = {
  token: null,
  refreshTokenDuration: null,
  error: null,
  expired: false,
};

const getters = {
  isAuthenticated: (s) => !!s.token,
  authError: (s) => s.error,
  token: (s) => s.token,
  refreshTokenDuration: (s) => s.refreshTokenDuration,
  isExpired: (s) => s.expired,
};

const actions = {
  clear: ({ commit }) => {
    commit('clear');
  },
  authenticate: ({ commit }, credentials) => {
    return new Promise((resolve, reject) => {
      authService.login(credentials).then(
        (result) => {
          // eslint-disable-next-line no-prototype-builtins
          if (!result.succeeded) {
            commit('authError', result.errors[0]);
          } else {
            commit('success', result.data);
          }
          resolve(result);
        },
        (error) => {
          commit('authError', 'An unknown error was encountered');
          reject(error);
        },
      );
    });
  },
  logOff: ({ commit, dispatch }) => {
    authService.logoff().then(() => {
      commit('clear');
      dispatch('clear', null, {
        root: true,
      });
    });
  },
  // eslint-disable-next-line no-unused-vars
  refresh: ({ commit }) => {
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
      authService
        .refresh()
        .then((result) => {
          if (result.succeeded) {
            commit('success', result.data);
          }
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  clear: (s) => {
    s.token = null;
    s.error = null;
  },
  success: (state, data) => {
    state.error = null;
    state.token = data.jwt;
    state.refreshTokenDuration = data.refreshTokenDuration;
    state.expired = false;
    setTimeout(() => {
      state.expired = true;
    }, data.refreshTokenDuration * 1000);
    EventBus.$emit('start_clock', data.refreshTokenDuration);
  },
  authError: (state, error) => {
    state.token = '';
    state.error = error;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
