<template>
  <v-dialog v-model="dialog" max-width="290" persistent overlay-opacity="0.98">
    <v-card>
      <v-card-title class="headline"> Continue? </v-card-title>
      <v-card-text>
        Your session will expire in {{ countDown }} seconds. Would you like to
        continue?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="continueSession" width="100%" text>
          Continue
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus } from '../event-bus';
import { mapGetters } from 'vuex';

export default {
  name: 'LogoutTimer',
  data: () => {
    return {
      dialog: false,
      timer: null,
      interactionRefreshTimer: null,
      countDown: 0,
      doCountdown: false,
      lastInteraction: null,
    };
  },
  computed: mapGetters({
    isAuthenticated: 'auth/isAuthenticated',
    isExpired: 'auth/isExpired',
  }),
  mounted() {
    EventBus.$on('stop_clock', this.stopClock);
    EventBus.$on('start_clock', this.startClock);
  },
  methods: {
    getSecondsSinceLastInteraction() {
      if (this.lastInteraction !== null) {
        return Math.abs(
          (new Date().getTime() - this.lastInteraction.getTime()) / 1000,
        );
      }
      return null;
    },
    continueSession() {
      // refresh token
      this.doCountdown = false;
      this.countDown = 0;
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.dialog = false;
      this.$store.dispatch('auth/refresh').then((res) => {
        if (!res.succeeded) {
          this.showSessionExpiredSnack();
          this.logoff();
        }
      });
    },
    showSessionExpiredSnack() {
      this.$store.commit(
        'snackbar/showSnack',
        'Session expired. You have been automatically logged out.',
      );
    },
    stopClock() {
      clearTimeout(this.timer);
    },
    countdownTimer() {
      if (!this.doCountdown) return;
      // if the browser tab was not focused then
      // this countdown can happen after the user
      // should have already been logged out.
      if (this.isExpired) {
        this.dialog = false;
        this.showSessionExpiredSnack();
        this.logoff();
        return;
      } else {
        if (this.countDown > 0) {
          setTimeout(() => {
            this.countDown -= 1;
            this.countdownTimer();
          }, 1000);
        } else {
          this.dialog = false;
          this.showSessionExpiredSnack();
          this.logoff();
        }
      }
    },
    logoff() {
      this.$store.dispatch('auth/logOff').then(() => {
        this.$router.push('/login').catch(() => {});
      });
    },
    // the idea with this is to have a check 10 seconds before
    // the dialog is supposed to show to see if the last interaction
    // happened within the last 2 mins (120 seconds). If it has, then
    // go ahead and try to refresh the token and extend the session
    // automatically.
    startInteractionRefreshTimer(seconds) {
      var t = this;
      if (this.interactionRefreshTimer) {
        clearTimeout(this.interactionRefreshTimer);
      }
      const timeout = (seconds - 40) * 1000;
      t.interactionRefreshTimer = setTimeout(() => {
        if (t.isAuthenticated) {
          let secondsSinceLastInteraction = t.getSecondsSinceLastInteraction();
          if (
            secondsSinceLastInteraction !== null &&
            secondsSinceLastInteraction < 120
          ) {
            // It has been less then 2 mins since last interaction, so
            // try a refresh
            t.$store.dispatch('auth/refresh');
          }
        }
      }, timeout);
    },
    startClock(seconds) {
      this.startInteractionRefreshTimer(seconds);
      const timeout = (seconds - 30) * 1000;
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.dialog = true;
        this.countDown = 30;
        this.doCountdown = true;
        this.countdownTimer();
      }, timeout);
    },
    activateActivityTrackers() {
      window.addEventListener('keydown', this.interactionEvent);
      window.addEventListener('mousedown', this.interactionEvent);
    },
    deactivateActivityTrackers() {
      window.removeEventListener('keydown', this.interactionEvent);
      window.removeEventListener('mousedown', this.interactionEvent);
    },
    interactionEvent() {
      if (this.isAuthenticated) {
        this.lastInteraction = new Date();
      }
    },
  },
  beforeMount() {
    this.activateActivityTrackers();
  },
  beforeDestroy() {
    this.deactivateActivityTrackers();
  },
  render() {
    return this.$slots.default;
  },
};
</script>
