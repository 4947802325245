<template>
  <v-dialog v-model="show" :max-width="width">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon small v-on="on" v-bind="attrs">
        <slot name="icon">
          <v-icon>mdi-information-outline</v-icon>
        </slot>
      </v-btn>
    </template>
    <v-card tile>
      <v-card-title class="word-wrap-normal"
        ><slot name="title"></slot
      ></v-card-title>
      <v-card-text>
        <slot name="text"></slot>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="primary accent-4" @click="show = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 350,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    choose(value) {
      this.$emit('result', value);
      this.value = value;
      this.$destroy();
    },

    change() {
      this.$destroy();
    },
  },
};
</script>
