// eslint-disable-next-line no-unused-vars
const Dollar = function (dollars) {
  this.cents = amountToNumber(dollars);

  this.amountToNumber = amountToNumber;
  this.isEqualTo = isEqualTo;
  this.isLessThan = isLessThan;
  this.isLessThanOrEqualTo = isLessThanOrEqualTo;
  this.isGreaterThan = isGreaterThan;
  this.isGreaterThanOrEqualTo = isGreaterThanOrEqualTo;
  this.add = add;
  this.subtract = subtract;
  this.toFloat = toFloat;

  function amountToNumber(amt) {
    let nbr = amt.toFixed(2);
    let str = nbr.split(',').join('').split('.').join('');
    return Number(str);
  }

  function isEqualTo(dollar) {
    return dollar.cents === this.cents;
  }

  function isLessThan(dollar) {
    return this.cents < dollar.cents;
  }

  function isLessThanOrEqualTo(dollar) {
    return this.cents <= dollar.cents;
  }

  function isGreaterThan(money) {
    return this.cents > money.cents;
  }

  function isGreaterThanOrEqualTo(money) {
    return this.cents >= money.cents;
  }

  function add(dollar) {
    let result = toFloatInternal(this.cents + dollar.cents);
    return new Dollar(parseFloat(result));
  }

  function subtract(dollar) {
    let result = toFloatInternal(this.cents - dollar.cents);
    return new Dollar(parseFloat(result));
  }

  function toFloatInternal(cents) {
    const sign = cents < 0 ? '-' : '';
    const columns =
      cents < 0
        ? cents.toString().split('').slice(1, Infinity)
        : cents.toString().split('');
    const result = columns.slice();
    const dotLocation = columns.length - 2;

    if (dotLocation < 0) {
      return sign + '0.0' + result.join('');
    }
    if (dotLocation === 0) {
      return sign + '0.' + result.join('');
    }
    if (dotLocation > 0) {
      result.splice(dotLocation, 0, '.');
      return sign + result.join('');
    }
  }

  function toFloat() {
    const sign = this.cents < 0 ? '-' : '';
    const columns =
      this.cents < 0
        ? this.cents.toString().split('').slice(1, Infinity)
        : this.cents.toString().split('');

    const result = columns.slice();
    const dotLocation = columns.length - 2;

    if (dotLocation < 0) {
      return parseFloat(sign + '0.0' + result.join(''));
    }
    if (dotLocation === 0) {
      return parseFloat(sign + '0.' + result.join(''));
    }
    if (dotLocation > 0) {
      result.splice(dotLocation, 0, '.');
      return parseFloat(sign + result.join(''));
    }
  }
};

export default Dollar;
