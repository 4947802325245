<template>
  <centered-content-area>
    <v-slide-y-transition mode="out-in">
      <make-payment-component
        :loan="internalLoan"
        :location="location"
        :paymentInfo.sync="paymentInfo"
        v-if="internalLoan && step === 0"
        key="step0"
        @makePaymentClicked="onMakeAPaymentClicked"
        @cancel="cancel"
        :errors="errors"
        :isAfterHours="isAfterHours"
      >
      </make-payment-component>

      <payment-verification-component
        :paymentInfo.sync="paymentInfo"
        :loan="internalLoan"
        v-if="internalLoan && step === 1"
        key="step1"
        v-on:submitPayment="submitPayment"
        @cancel="cancel"
        :errors="errors"
        :showProgress="showProgress"
      >
      </payment-verification-component>

      <payment-receipt-component
        :paymentInfo="paymentInfo"
        :loan="loan"
        v-if="internalLoan && step === 2"
        key="step2"
      >
      </payment-receipt-component>
    </v-slide-y-transition>
  </centered-content-area>
</template>

<script>
import { format } from 'date-fns';
import { mapGetters } from 'vuex';
import CenteredContentArea from '../../components/CenteredContentArea';
import MakePaymentComponent from '../../components/loan/MakePaymentComponent';
import PaymentVerificationComponent from '../../components/loan/PaymentVerificationComponent';
import PaymentReceiptComponent from '../../components/loan/PaymentReceiptComponent';
import { timeFuncs } from '../../mixins/timeFuncs';
import { differenceInMilliseconds, differenceInMinutes } from 'date-fns';
import { loanService } from '../../services/loan.service';
import { paymentService } from '../../services/payment.service';

export default {
  name: 'MakeAPayment',
  props: ['loan', 'location'],
  mixins: [timeFuncs],
  components: {
    CenteredContentArea,
    MakePaymentComponent,
    PaymentVerificationComponent,
    PaymentReceiptComponent,
  },
  created() {
    if (!this.loan) {
      return this.cancel();
    }
    this.paymentInfo.loanNumber = this.loan.loanNumber;
    this.paymentInfo.paymentDate = this.getCurrentArizonaDate();
    this.paymentInfo.postingDate = this.getCurrentArizonaDate();
    this.setAfterHoursTimer();
    this.setPaymentDateTimer();
  },
  data: () => {
    return {
      step: 0,
      timerActive: false,
      isAfterHours: false,
      refreshedLoan: null,
      errors: null,
      showProgress: false,
      postingDateAdvanced: false,
      paymentInfo: {
        paymentMethod: null,
        loanNumber: null,
        paymentAmount: null,
        paymentDate: null,
        postingDate: null,
        interestAndFees: null,
        principal: null,
        transactionId: null,
      },
    };
  },
  methods: {
    refreshLoan(formattedAfterHoursCutoff, afterHoursPostingDate) {
      var t = this;
      this.loading = true;
      loanService
        .getLoan(this.loan.loanNumber)
        .then((res) => {
          this.refreshedLoan = res.data;
          t.showSnack(
            `Warning!  Your payment was not submitted by the cutoff time of ${formattedAfterHoursCutoff} for it to be posted to your account today.  Your payment amounts have been refreshed to reflect that your payment will now be posted to your account with an effective date of ${afterHoursPostingDate.toLocaleDateString(
              'en-US',
            )}.`,
          );
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setPaymentDateTimer() {
      var t = this;
      setTimeout(function () {
        var d = t.getCurrentArizonaDate();
        t.paymentInfo.paymentDate = d;
        t.setPaymentDateTimer();
      }, 1000);
    },
    setAfterHoursTimer() {
      let t = this;
      var d = this.getCurrentArizonaDate();
      var hours = this.getLocationHours(this.location, d);

      if (hours.closeTime.hour === null) return;
      if (d.getHours() > hours.closeTime.hour) {
        t.isAfterHours = true;
        t.advancePostingDate();
        return;
      }

      let afterHoursCutoff = new Date(
        d.getFullYear(),
        d.getMonth(),
        d.getDate(),
        hours.closeTime.hour,
        hours.closeTime.minutes,
      );

      afterHoursCutoff.setMinutes(
        afterHoursCutoff.getMinutes() -
          this.location.priorToCloseInMinutesForAfterHoursPayments,
      );

      let diffInMilliseconds = differenceInMilliseconds(afterHoursCutoff, d);

      if (diffInMilliseconds < 0) {
        this.advancePostingDate();
        this.isAfterHours = true;
        // already after hours
        return;
      }

      var formattedAfterHoursCutoff = afterHoursCutoff.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      });

      // Only the final notification remains
      if (diffInMilliseconds <= 300000) {
        setTimeout(function () {
          let afterHoursPostingDate = new Date(d.getTime());
          afterHoursPostingDate.setDate(afterHoursPostingDate.getDate() + 1);
          t.isAfterHours = true;
          t.refreshLoan(formattedAfterHoursCutoff, afterHoursPostingDate);
          t.advancePostingDate();
        }, diffInMilliseconds);
        return;
      }

      var msToNextWarning = this.getMsToNextWarning(diffInMilliseconds);

      if (msToNextWarning) {
        setTimeout(function () {
          let diffInMinutes = differenceInMinutes(
            afterHoursCutoff,
            t.getCurrentArizonaDate(),
          );
          t.showSnack(
            `Warning!  You have ${diffInMinutes} minutes to submit and verify your payment in order for it to be posted to your account today.  Payments submitted after ${formattedAfterHoursCutoff} will be posted the next business day.`,
          );
          t.setAfterHoursTimer();
        }, msToNextWarning);
      }
    },
    advancePostingDate() {
      // 1st level safeguard
      if (this.postingDateAdvanced) return;
      // 2nd level safeguard
      var d = this.getCurrentArizonaDate();
      if (this.paymentInfo.postingDate.getDate() > d.getDate() + 1) return;

      const copy = new Date(Number(this.paymentInfo.postingDate));
      copy.setDate(this.paymentInfo.postingDate.getDate() + 1);
      this.paymentInfo.postingDate = copy;
      this.postingDateAdvanced = true;
    },
    showSnack(message) {
      this.$store.dispatch('snackbar/showSnack', { message, timeout: 9000 });
    },
    getMsToNextWarning(diffInMilliseconds) {
      if (diffInMilliseconds > 0) {
        // 15 mins
        if (diffInMilliseconds > 900000) {
          return diffInMilliseconds - 900000;
        }
        // 10 mins
        if (diffInMilliseconds > 600000) {
          return diffInMilliseconds - 600000;
        }
        // 5 mins
        if (diffInMilliseconds > 300000) {
          return diffInMilliseconds - 300000;
        }
      }
      return 0;
    },
    onMakeAPaymentClicked(payload) {
      this.paymentInfo = payload;
      this.step = 1;
    },
    submitPayment() {
      this.showProgress = true;
      let payment = {
        loanNumber: this.loan.loanNumber,
        paymentDateTime: format(
          this.paymentInfo.paymentDate,
          'MM/dd/yyyy HH:mm:ss',
        ),
        afterHours: this.isAfterHours,
        postingDate: format(
          this.paymentInfo.postingDate,
          'MM/dd/yyyy HH:mm:ss',
        ),
        paymentAmount: this.paymentInfo.paymentAmount,
        paymentPrincipal: this.paymentInfo.principal,
        paymentInterestAndFees: this.paymentInfo.interestAndFees,
        paymentMethod: this.paymentInfo.paymentMethod,
      };

      paymentService
        .makePayment(payment)
        .then((res) => {
          if (res.succeeded) {
            this.paymentInfo.transactionId = res.data.transactionId;
            this.step = 2;
          } else {
            this.errors = res.errors;
          }
        })
        .catch((err) => {
          this.errors.splice(0, this.errors.length);
          this.errors.push(err);
        })
        .finally(() => {
          this.showProgress = false;
        });
    },
    cancel() {
      this.$router.push('/loan-details');
    },
  },
  computed: {
    ...mapGetters({
      locations: 'location/locations',
      locationsLoaded: 'location/loaded',
      getLocationById: 'location/getLocationById',
    }),
    internalLoan() {
      return this.refreshedLoan ? this.refreshedLoan : this.loan;
    },
  },
};
</script>
