export const validationRules = {
  data() {
    return {
      emailRules: [
        (v) => !!v || 'Email is required',
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          'E-mail must be valid',
      ],
      firstNameRules: [(v) => !!v || 'First name is required'],
      lastNameRules: [(v) => !!v || 'Last name is required'],
      passwordRules: [(v) => !!v || 'Password is required'],
    };
  },
};
