<template>
  <v-card>
    <v-card-text>
      <div class="emphasis" style="text-align: center">
        Congratulations!<br />
        Your account is ready.
      </div>
      <div class="mt-2 emphasis" style="text-align: center">
        Please link your current loan to get started:
      </div>
    </v-card-text>
    <v-card-actions>
      <div class="separator"></div>
      <v-btn color="secondary" to="/link-my-loan" width="100%">
        Link My Loan
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'AccountIsReady',
};
</script>
