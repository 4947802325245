<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <v-snackbar v-model="show" :timeout="timeout" :color="isError ? error : null">
    {{ message }}
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      message: '',
      watchFunction: null,
      timeout: 5000,
      isError: false,
    };
  },
  created: function () {
    this.watchFunction = this.$store.watch(
      (state) => state.snackbar.snack,
      () => {
        this.timeout = this.$store.state.snackbar.timeout;
        const msg = this.$store.state.snackbar.snack;
        this.isError = this.$store.state.isError;
        if (msg !== '') {
          this.show = true;
          this.message = this.$store.state.snackbar.snack;
          this.$store.commit('snackbar/showSnack', '');
        }
      },
    );
  },
  destroyed: function () {
    if (this.watchFunction) {
      this.watchFunction();
    }
  },
};
</script>
