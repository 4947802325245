import { format } from 'date-fns';

export default function formatDate(value, dateFormat) {
  if (value === null || value === undefined) {
    return '';
  }

  if (dateFormat === null || dateFormat === undefined) {
    dateFormat = 'MMM d, yyyy';
  }
  return format(Date.parse(value), dateFormat);
}
