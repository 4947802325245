'use strict';

const Luhn = {
  isValid: function (cardNumber) {
    if (!cardNumber) return false;
    if (cardNumber.length < 13) return false;
    const digits = [...cardNumber];
    var oddSum = 0;
    var evenSum = 0;
    for (var i = 0; i < digits.length; i++) {
      if (i % 2 === 0) {
        if (digits[i] * 2 >= 10) {
          evenSum += digits[i] * 2 - 9;
        } else {
          evenSum += digits[i] * 2;
        }
      } else {
        oddSum += parseInt(digits[i]);
      }
    }
    return (oddSum + evenSum) % 10 === 0;
  },
};

export default Luhn;
