var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loan)?_c('v-card',[_c('v-card-title',[_vm._v(" Title Loan:"),_c('span',{staticClass:"uppercase pl-1"},[_vm._v(" "+_vm._s(_vm.loan.loanNumber)+" ")])]),_c('v-card-text',[_c('loan-detail-basic-info',{attrs:{"loan":_vm.loan,"location":_vm.location}}),_c('v-btn',{attrs:{"disabled":_vm.loan.noCustomerPortalPaymentsAllowed ||
        _vm.hasPaymentsPending ||
        _vm.loan.currentLoanStatus === 'Closed' ||
        !_vm.location,"block":"","color":"secondary"},on:{"click":_vm.handleMakeAPaymentClicked}},[_vm._v("Make A Payment")]),_c('v-dialog',{attrs:{"max-width":"350"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Payment Limit")]),_c('v-card-text',[_vm._v(" Your accumulated payments for this day has reached or exceeded the daily limit. Please contact the location of your loan. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.showDialog = false}}},[_vm._v("OK")])],1)],1)],1),(_vm.hasPaymentsPending)?_c('v-alert',{staticClass:"elevation-2 mt-2",attrs:{"type":"warning","icon":"warning","border":"top"}},[_vm._v(" You have a pending "+_vm._s(_vm.loan.pendingPaymentType)+" payment that was made on "+_vm._s(_vm._f("formatDate")(_vm.loan.pendingPaymentDate))+" for "+_vm._s(_vm._f("currency")(_vm.loan.pendingPaymentAmount))+". This payment must be posted before you can make any additional payments. ")]):_vm._e(),(_vm.loan.currentLoanStatus === 'Closed' && _vm.location)?_c('p',{staticClass:"pt-2 font-bold color-red ta-c",staticStyle:{"font-size":"15px"}},[_vm._v(" This loan is closed. If you have received an increase you must link your new loan number to see current balances and make a payment. Please call customer service at "+_vm._s(_vm._f("formatPhone")(_vm.location.phone))+". ")]):_vm._e(),(
        _vm.loan.currentLoanStatus !== 'Closed' &&
        _vm.loan.noCustomerPortalPaymentsAllowed &&
        _vm.location
      )?_c('p',{staticClass:"pt-2 font-bold color-red ta-c",staticStyle:{"font-size":"15px"}},[_vm._v(" Unable to make a customer portal payment for this account. Please call customer service at "+_vm._s(_vm._f("formatPhone")(_vm.location.phone))+". ")]):_vm._e(),_c('separator',{staticClass:"pb-3 mt-4 mb-2"}),_c('name-value-pair-row',{attrs:{"name":"Loan Origination","value":_vm._f("formatDate")(_vm.loan.loanOriginationDate)}}),(!_vm.loan.isArd)?_c('name-value-pair-row',{attrs:{"name":"Principal Balance","value":_vm._f("currency")(_vm.loan.principalBalance)}}):_vm._e(),_c('name-value-pair-row',{attrs:{"name":"Last Payment","value":_vm._f("currency")(_vm.loan.lastPaymentAmount)}}),_c('name-value-pair-row',{attrs:{"name":"Last Payment Date","value":_vm._f("formatDate")(_vm.loan.lastPaymentDate)}}),(!_vm.loan.isArd)?_c('name-value-pair-row',{attrs:{"name":"Maturity Date","value":_vm._f("formatDate")(_vm.loan.maturityDate)}}):_vm._e(),_c('loan-history-dialog',{attrs:{"loan":_vm.loan}}),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"font-small ta-c",attrs:{"cols":"10"}},[_vm._v(" * Information or transactions may take up to 1 business day to display. ")])],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }