<template>
  <account-card>
    <template v-slot:progress>
      <v-progress-linear
        v-if="isBusy"
        :indeterminate="true"
        style="margin: 0"
        color="accent"
      ></v-progress-linear>
    </template>
    <template v-slot:default>
      <div class="title">LOGIN TO YOUR ACCOUNT</div>
      <error-display v-bind:error="error" />
      <v-form v-model="valid" @keyup.native.enter="handleSubmit">
        <v-text-field
          label="Email"
          v-model="credentials.userName"
          :rules="emailRules"
          :type="'email'"
          required
          autofocus
          autocomplete="username"
          :disabled="isBusy"
        >
        </v-text-field>
        <v-text-field
          label="Password"
          v-model="credentials.password"
          :rules="passwordRules"
          :type="'password'"
          required
          name="password"
          autocomplete="current-password"
          :disabled="isBusy"
        >
        </v-text-field>
        <div class="pt-2">
          <router-link to="/forgot-password">
            Reset/Forgot Password
          </router-link>
        </div>
        <v-btn
          class="mt-4"
          color="secondary darken-1"
          style="width: 220px"
          @click="handleSubmit"
          :disabled="!valid || isBusy || isLockedOut"
        >
          Login
        </v-btn>
      </v-form>
      <div class="separator">
        <span>OR</span>
      </div>
      <div class="register">
        <span>Don't have an account?</span>
        <router-link class="register_link" to="/register"
          >Create account</router-link
        >
      </div>
    </template>
  </account-card>
</template>

<script>
import { mapGetters } from 'vuex';
import AccountCard from './AccountCard';
import ErrorDisplay from '../../components/ErrorDisplay';
import { validationRules } from '../../mixins/validationRules';
export default {
  name: 'LoginForm',
  components: {
    AccountCard,
    ErrorDisplay,
  },
  mixins: [validationRules],
  computed: mapGetters({
    isAuthenticated: 'auth/isAuthenticated',
    authError: 'auth/authError',
    companyId: 'company/companyId',
    companyLoaded: 'company/loaded',
  }),
  data: function () {
    return {
      isBusy: false,
      error: null,
      valid: false,
      isLockedOut: false,
      credentials: {
        userName: '',
        password: '',
        companyId: null,
        platform: 'Web',
      },
    };
  },
  methods: {
    handleSubmit() {
      if (!this.companyLoaded) {
        this.error = null;
        this.isBusy = true;
        this.$store
          .dispatch('company/getCompanyById', this.companyId)
          .then(() => {
            this.login();
          })
          .catch((err) => {
            console.error(err);
            this.error = 'The server is unavailable. Please try again later.';
          })
          .finally(() => {
            this.isBusy = false;
          });
      } else {
        this.login();
      }
    },
    login() {
      if (!this.valid) {
        return;
      }
      this.isBusy = true;
      this.error = null;
      this.credentials.companyId = this.companyId;
      this.$store
        .dispatch('auth/authenticate', this.credentials)
        .then((res) => {
          let t = this;
          if (!res.succeeded) {
            if (res.data.isLockedOut) {
              this.isLockedOut = true;
              setTimeout(() => {
                t.isLockedOut = false;
              }, 300000);
            }
            if (
              res.errors.length > 0 &&
              res.errors[0] === 'Account not verified.'
            ) {
              this.$store.commit(
                'account/registrationName',
                res.data.firstName,
              );
              this.$store.commit(
                'account/registrationEmail',
                this.credentials.userName,
              );
              this.$router.push({
                name: 'verifyAccount',
              });
              return;
            }
          }
          if (this.isAuthenticated) {
            // preload locations
            this.$store.dispatch('location/getLocations');

            // Determine where to go after login
            this.$store.dispatch('account/getProfile');
            if (
              this.$route.query.redirect &&
              this.$route.query.redirect !== '/'
            ) {
              this.$router.push({ path: this.$route.query.redirect });
            } else {
              var destination = res.data.hasLinkedLoans ? 'loan-details' : '/';
              this.$router.push({ path: destination });
            }
          } else if (this.authError) {
            this.error = this.authError;
          }
        })
        .catch((err) => {
          this.error = err;
        })
        .then(() => {
          this.isBusy = false;
        });
    },
  },
  created() {
    if (this.$route.query.email) {
      this.credentials.userName = this.$route.query.email;
      this.credentials.password = null;
    }
  },
  watch: {
    credentials: {
      // eslint-disable-next-line no-unused-vars
      handler(val) {
        this.error = null;
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 20px;
  margin: 16px 0 16px;
  font-weight: normal;
}
.register {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  max-width: 100%;
  align-items: center;
}
a,
.register > span {
  font-weight: 500;
}
a {
  text-decoration: none;
  font-size: 15px;
}
a:hover {
  text-decoration: underline;
}
.separator {
  font-size: 15px;
  font-weight: 600;
  margin: 24px auto 18px auto;
  position: relative;
  overflow: hidden;
  width: 100px;
  color: rgba(0, 0, 0, 0.54);
}
.separator span {
  display: inline-flex;
  position: relative;
  padding: 0 8px;
  z-index: 9999;
}
.separator span::before {
  right: 100%;
  content: '';
  display: block;
  width: 30px;
  position: absolute;
  top: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.16);
}
.separator span::after {
  left: 100%;
  content: '';
  display: block;
  width: 30px;
  position: absolute;
  top: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.16);
}
::after,
::before {
  box-sizing: border-box;
}
.error-container {
  min-height: 33px;
  margin-bottom: 5px;
  position: relative;
}
.error {
  padding: 6px;
  color: #fff;
  border-radius: 2px;
}
</style>
