<template>
  <v-card>
    <v-card-title> Title Loan: {{ paymentInfo.loanNumber }} </v-card-title>
    <v-card-text>
      <separator />
      <div class="ta-c title primary--text mt-4">
        Your payment is being processed.
      </div>
      <div class="ta-c title primary--text mt-1 mb-5">
        Please review your email to confirm posting to your account.
      </div>
      <name-value-pair-row
        name="Payment Date:"
        :value="paymentInfo.paymentDate | formatDate('MMM d, yyyy p')"
      ></name-value-pair-row>
      <name-value-pair-row
        name="Posting Date:"
        :value="paymentInfo.postingDate | formatDate"
      ></name-value-pair-row>
      <name-value-pair-row
        name="Total Payment Amount:"
        :value="paymentInfo.paymentAmount | currency"
      ></name-value-pair-row>
      <name-value-pair-row
        v-if="!loan.isArd"
        name="Payment to Principal:"
        :value="paymentInfo.principal | currency"
      ></name-value-pair-row>
      <name-value-pair-row
        v-if="!loan.isArd"
        name="Payment to Interest:"
        :value="paymentInfo.paymentAmount | currency"
      ></name-value-pair-row>
      <name-value-pair-row
        v-if="!loan.isArd"
        name="Payment to Fees:"
        :value="paymentInfo.interestAndFees | currency"
      ></name-value-pair-row>
      <name-value-pair-row
        name="Your transaction number:"
        :value="paymentInfo.transactionId"
      ></name-value-pair-row>
      <separator class="mt-3 mb-3" />
      <div class="ta-c">
        * Once your payment has been successfully processed you will be emailed
        a receipt. If there are any errors while attempting to process your
        payment you will be informed of the error and you will need to enter a
        new payment request or contact your loan center for further assistance.
        Information or transactions may take up to 1 business day to display.
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import NameValuePairRow from '../NameValuePairRow';
export default {
  components: {
    NameValuePairRow,
  },
  props: ['paymentInfo', 'loan'],
};
</script>
