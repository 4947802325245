<template>
  <centered-content-area>
    <account-is-ready class="mb-4"></account-is-ready>
    <!-- <apply-now></apply-now> -->
  </centered-content-area>
</template>

<script>
import AccountIsReady from '../components/AccountIsReady';
//import ApplyNow from '../components/ApplyNow';
import CenteredContentArea from '../components/CenteredContentArea';
import { loanService } from '../services/loan.service';
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Dashboard',
  components: {
    CenteredContentArea,
    AccountIsReady,
    //ApplyNow
  },
  created() {
    loanService.hasLinkedLoans().then((res) => {
      if (res.data) {
        this.$router.push('/loan-details');
      }
    });
  },
};
</script>

<style lang="scss">
.emphasis {
  font-size: 1.18rem !important;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0.0125em !important;
  font-family: 'Roboto', sans-serif !important;
}
</style>
