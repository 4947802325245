<template>
  <v-autocomplete
    solo
    label="Search Locations"
    v-model="selection"
    :search-input.sync="searchText"
    :items="locations"
    item-text="value"
    item-value="id"
    return-object
    hide-details
    class="pb-3 autocomplete-remove-icon-rotate"
    clearable
    append-icon=""
    :loading="loading"
    ref="locationSearch"
    :hide-no-data="!searchText || searchText.length < 3"
  >
  </v-autocomplete>
</template>

<script>
export default {
  name: 'PlacesAutoComplete',
  data() {
    return {
      selection: null,
      searchText: null,
      locations: [],
      timer: null,
      loading: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.locationSearch.focus();
    });
  },
  methods: {
    searchLocation(val) {
      let t = this;
      return new Promise((resolve, reject) => {
        var displaySuggestions = (predictions, status) => {
          // eslint-disable-next-line no-undef
          if (status !== google.maps.places.PlacesServiceStatus.OK) {
            reject(status);
          }
          resolve(predictions);
        };

        // eslint-disable-next-line no-undef
        var service = new google.maps.places.AutocompleteService();
        service.getPlacePredictions(
          {
            input: val,
            types: ['geocode'],
          },
          displaySuggestions,
        );
      }).catch(function (err) {
        //console.error('err', err);
        if (err === 'ZERO_RESULTS') {
          if (t.locations.length > 0) {
            t.locations.splice(0, this.locations.length);
            return;
          }
        }
      });
    },
    getSuggestions(searchText) {
      this.loading = true;
      this.searchLocation(searchText)
        .then((res) => {
          if (!res || res.length === 0) {
            this.locations.splice(0, this.locations.length);
            return;
          }
          this.locations = res.map((res) => {
            return {
              id: res.place_id,
              value: res.description,
            };
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    selection(newVal) {
      this.$emit('changed', newVal ? newVal.id : null);
    },
    searchText(newVal) {
      // If less than 3 chars typed, do not search
      if (!newVal || newVal.length < 3) {
        if (this.locations.length > 0) {
          this.locations.splice(0, this.locations.length);
        }
        return;
      }

      if (this.timer) {
        clearTimeout(this.timer);
      }

      this.timer = setTimeout(() => {
        this.getSuggestions(newVal);
      }, 200);
    },
  },
};
</script>
