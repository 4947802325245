import currency from './currency';
import formatDate from './formatDate';
import formatPhone from './formatPhone';

export default {
  install(Vue) {
    Vue.filter('currency', currency);
    Vue.filter('formatDate', formatDate);
    Vue.filter('formatPhone', formatPhone);
  },
};
