<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="formattedDate"
        :label="label"
        append-icon="event"
        readonly
        v-bind="attrs"
        v-on="on"
        @focus="onFocus"
        :rules="rules"
        :disabled="disabled"
      ></v-text-field>
    </template>
    <v-date-picker
      ref="picker"
      no-title
      v-model="date"
      scrollable
      :max="max"
      :min="min"
      @change="save"
      type="month"
      :disabled="disabled"
    >
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: 'DatePicker',
  props: {
    startWithYear: Boolean,
    max: [String, Date],
    min: [String, Date],
    label: String,
    rules: Array,
    disabled: Boolean,
  },
  data: () => {
    return {
      menu: false,
      formattedDate: null,
      date: null,
    };
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      const [year, month] = date.split('-');
      return `${month}/${year}`;
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    onFocus() {
      this.menu = true;
    },
  },
  watch: {
    menu(val) {
      if (!this.startWithYear) return;
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'));
    },
    // eslint-disable-next-line no-unused-vars
    date: function (val) {
      this.formattedDate = this.formatDate(this.date);
      this.$emit('input', this.formattedDate);
      this.$emit('raw', val);
    },
    disabled(val) {
      if (val) {
        this.menu = false;
      }
    },
    formattedDate: function (val) {
      if (val === null) {
        this.date = null;
      }
    },
  },
};
</script>
