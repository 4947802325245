<template>
  <div class="error-container" v-if="error || (errors && errors.length > 0)">
    <transition name="scale-transition" mode="out-in">
      <div class="error pa-2" v-if="error">
        {{ error }}
      </div>
      <div class="error pa-2" v-if="errors && errors.length > 0">
        <ul>
          <li v-for="e in errors" :key="e">
            {{ e }}
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'ErrorDisplay',
  props: ['error', 'errors'],
};
</script>

<style scoped>
.error-container {
  min-height: 33px;
  margin-bottom: 5px;
  position: relative;
}
.error {
  color: #fff;
  border-radius: 2px;
}
li {
  text-align: left;
}
</style>
