export const timeFuncs = {
  methods: {
    getCurrentArizonaDate() {
      return new Date(
        new Date().toLocaleString('en-US', { timeZone: 'US/Arizona' }),
      );
    },
    getLocationHours(location, date) {
      var day = date
        .toLocaleDateString('en-US', { weekday: 'long' })
        .toLowerCase();
      return location[`${day}Hours`];
    },
  },
};
