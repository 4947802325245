<template>
  <v-text-field
    :label="label"
    v-bind="$attrs"
    v-bind:value="value"
    :clearable="clearable"
    @click:clear="onClearClicked"
    v-on:keypress="keyPress"
    :autocomplete="autocomplete"
    :rules="rules"
  ></v-text-field>
</template>

<script>
import Cleave from 'cleave.js';
export default {
  name: 'MaskedInput',
  props: [
    'rules',
    'value',
    'option',
    'raw',
    'label',
    'clearable',
    'numeric',
    'autocomplete',
  ],
  data: function () {
    return {
      cleave: null,
    };
  },
  mounted() {
    const input = this.$el.querySelector('input');
    this.cleave = new Cleave(input, {
      ...this.option,
      onValueChanged: this.onValueChanged.bind(this),
    });
    this.cleave.setRawValue(this.value);
  },
  methods: {
    onClearClicked() {
      this.$nextTick(() => {
        this.$emit('input', null);
        this.$emit('raw', null);
      });
    },
    onValueChanged({ target }) {
      this.$nextTick(() => {
        this.$emit('input', target.value);
        this.$emit('raw', target.rawValue);
      });
    },
    keyPress(e) {
      if (this.numeric && isNaN(e.key)) {
        e.preventDefault();
        return false;
      }
    },
  },
  beforeDestroy() {
    if (this.cleave) {
      this.cleave.destroy();
      this.cleave = null;
    }
  },
};
</script>
