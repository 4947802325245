<template>
  <v-card class="mb-4">
    <v-card-title>
      <v-row class="pl-3 pr-3 mb-3">
        <v-flex>
          <span class="green-text">{{ headerText }}</span>
          <info-dialog
            v-if="loan.increaseEligibleAmount > 1"
            :width="550"
            :buttonColor="'text--green'"
          >
            <template v-slot:icon>
              <v-icon color="green" class="pb-1 pl-1"
                >mdi-information-outline</v-icon
              >
            </template>
            <template v-slot:title> My Available Cash </template>
            <template v-slot:text>
              You must continue to meet the criteria used to approve you for the
              available cash secured offer. Secured offers also require a first
              lien on a motor vehicle that meets our value requirements, titled
              in your name with valid insurance. Active duty military, their
              spouse or dependents covered by the Military Lending Act may not
              pledge any vehicle as collateral. If you are covered by the
              Military Lending Act, you are not eligible for a secured offer. If
              you are approved for an offer and have an open loan with
              {{ companyName }} or one of our affiliates, state lending laws may
              limit the total amount that can be loaned to you, may require us
              to payoff existing loans to provide you with a new loan, or may
              prevent us from offering the full amount you request. Loan
              proceeds cannot be used for postsecondary educational expenses as
              defined by the CFPB's Regulation Z such as college, university or
              vocational expenses; for any business or commercial purpose; to
              purchase securities; or for gambling or illegal purposes.
            </template>
          </info-dialog>
        </v-flex>
        <v-flex class="ta-r">
          <span class="green-text" style="font-size: 16px">
            {{ loan.loanNumber }}
          </span>
        </v-flex>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row class="pl-3 pr-3 mb-3" v-if="loan.increaseEligibleAmount === 1">
        <v-flex align-self-center>
          <span class="green-text" style="font-size: 20px"
            >Contact Store:
          </span>
        </v-flex>
        <v-flex class="ta-r" v-if="this.location">
          <v-btn
            v-if="$vuetify.breakpoint.mobile"
            color="green"
            dark
            @click="callLocation"
            >Call Now</v-btn
          >
          <a
            :href="`tel:+${location.phone}`"
            class="td-n"
            style="font-size: 20px"
            v-else
          >
            <v-icon class="green-text pr-2">phone</v-icon>
            <span class="green-text">{{ location.phone | formatPhone }}</span>
          </a>
        </v-flex>
      </v-row>
      <v-row class="pl-3 pr-2 mb-3" v-else>
        <v-flex align-self-center>
          <span class="green-text font-bold" style="font-size: 26px">
            {{ loan.increaseEligibleAmount | currency }}
          </span>
        </v-flex>
        <v-flex class="ta-r">
          <v-btn color="green" dark @click="initiateWorkflow">
            Get Cash Now
          </v-btn>
        </v-flex>
      </v-row>
      <v-divider
        class="mt-4"
        v-if="loan.increaseEligibleAmount !== 1"
      ></v-divider>
      <v-row
        class="pl-3 pr-2 mt-4 pb-2"
        v-if="loan.increaseEligibleAmount !== 1"
      >
        <span class="green-text" v-if="location"
          >If you need more than your available cash please call
          <a :href="`tel:+${this.location.phone}`" class="td-n green-text">
            <span>{{ location.phone | formatPhone }}</span>
          </a>
        </span>
      </v-row>
    </v-card-text>
    <v-card-actions
      v-if="loan.increaseEligibleAmount === 1"
      class="pr-3 pb-4 justify-center"
    >
      <v-btn class="green" block dark @click="initiateWorkflow"
        >Apply Now</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import InfoDialog from './../dialogs/InfoDialog.vue';
export default {
  name: 'IncreaseEligible',
  props: ['loan'],
  components: {
    InfoDialog,
  },
  data() {
    return {
      location: null,
    };
  },
  computed: {
    ...mapGetters({
      getLocationById: 'location/getLocationById',
      locationsLoaded: 'location/loaded',
      companyName: 'company/name',
    }),
    headerText() {
      return this.loan.increaseEligibleAmount === 1
        ? 'Get More Cash'
        : 'My Available Cash';
    },
  },
  created() {
    if (this.locationsLoaded) {
      this.setLocation();
    }
  },
  methods: {
    initiateWorkflow() {
      this.$router.push({
        name: 'increase',
        params: {
          loanNumber: this.loan.loanNumber,
        },
      });
    },
    setLocation() {
      this.location = this.getLocationById(this.loan.locationId);
    },
    callLocation() {
      window.open(`tel:+${location.phone}`, '_self');
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    locationsLoaded: function () {
      if (!this.location) {
        this.setLocation();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.green-text {
  color: #4caf50 !important;
}
</style>
